import styled from 'styled-components';
import { colors } from '.';

interface Props {
  backgroundColor: colors;
}

export const Button = styled.button<Props>`
  cursor: pointer;
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  border-radius: 56px;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  transform-origin: center;
  background-color: ${props =>
    props.backgroundColor === 'primary' ? '#fc5166' : '#fff'};
  border: 0px;

  ${props =>
    props.backgroundColor !== 'tertiary' && 'border: 2px solid #fc5166;'};

  :hover,
  :focus {
    background-color: ${props =>
      props.backgroundColor === 'primary'
        ? '#E1485B'
        : props.backgroundColor === 'secondary'
        ? '#Feecee'
        : '#E5ECF8'};
  }

  :disabled {
    background-color: ${props =>
      props.backgroundColor === 'primary' ? '#FDB8C1' : '#fff'};
    ${props =>
      props.backgroundColor !== 'tertiary'
        ? props.backgroundColor === 'primary'
          ? 'border: 2px solid #FDB8C1;'
          : 'border: 2px solid #E5ECF8;'
        : ''};
  }
`;

interface IconProps {
  backgroundColor: colors;
  disabled?: boolean;
}

export const Icon = styled.div<IconProps>`
  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${props =>
        props.disabled && props.backgroundColor === 'secondary'
          ? '#979EAF'
          : !props.disabled && props.backgroundColor === 'secondary'
          ? '#fc5166'
          : props.backgroundColor === 'primary'
          ? '#fff'
          : '#1A1818'};
    }
  }
`;
