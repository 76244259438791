import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../Icons';
import {
  Nav,
  ContainerList,
  ListItem,
  ButtonNav,
  ListMobile,
  ListMobileButton,
  Cross,
  LogoButton,
  TabBar,
  ButtonTab,
} from './styles';

interface LinkData {
  name: string;
  type: string;
}

const Links: LinkData[] = [
  { name: 'Simulação', type: 'SIMULATION' },
  { name: 'Contato', type: 'CONTACT' },
  { name: 'Começar', type: 'START' },
];

interface Props {
  isHide?: boolean;
  bodyRef?: any;
}
const Navbar: React.FC<Props> = ({ isHide = false, bodyRef }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const handleScroll = (type: string) => {
    let itemTop = 0;
    const maxLength = type === 'CONTACT' ? 7 : 1;

    for (let index = 0; index < maxLength; index += 1) {
      itemTop += bodyRef.current.children[index].clientHeight;
    }

    type !== 'START'
      ? window.scroll({
          top: itemTop,
          behavior: 'smooth',
        })
      : navigate('/register');

    setShowMenu(false);
  };

  return (
    <>
      <Nav>
        <LogoButton
          onClick={() =>
            pathname === '/'
              ? window.scroll({ top: 0, behavior: 'smooth' })
              : navigate('/')
          }
        >
          <Logo />
        </LogoButton>

        {!isHide && (
          <>
            <ContainerList>
              {Links.map(link => {
                return (
                  <ListItem key={link.type}>
                    <ButtonNav
                      checked={link.type === 'START'}
                      onClick={() => handleScroll(link.type)}
                    >
                      {link.name}
                    </ButtonNav>
                  </ListItem>
                );
              })}
            </ContainerList>

            <Cross active={showMenu} onClick={() => setShowMenu(!showMenu)} />
          </>
        )}
      </Nav>

      <ListMobile active={showMenu}>
        <ListMobileButton>
          {Links.map(link => {
            return (
              <ListItem key={link.name}>
                <ButtonNav
                  checked={link.type === 'START'}
                  onClick={() => handleScroll(link.type)}
                >
                  {link.name}
                </ButtonNav>
              </ListItem>
            );
          })}
        </ListMobileButton>
      </ListMobile>

      {!isHide && (
        <TabBar>
          <ButtonTab buttonType="primary" onClick={() => handleScroll('START')}>
            Começar!
          </ButtonTab>

          <ButtonTab
            buttonType="secondary"
            onClick={() => handleScroll('SIMULATION')}
          >
            Simular
          </ButtonTab>
        </TabBar>
      )}
    </>
  );
};

export { Navbar };
