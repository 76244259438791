import React from 'react';

interface Address {
  cep: string;
  uf: string;
  city: string;
  neighborhod: string;
  street: string;
  number: number;
  isNotNumber: boolean;
  complement: string;
}

interface PersonFisic {
  name: string;
  birthDate: string;
  cpf: string;
  rg: string;
  ocupationArea: string;
  email: string;
  phone: string;
}

interface PersonJuridic {
  reasonSocial: string;
  cnpj: string;
  ocupationArea: string;
  businessType: string;
  name: string;
  birthDate: string;
  cpf: string;
  rg: string;
  email: string;
  phone: string;
}

interface DoctorpayContextInterface {
  personFisic: PersonFisic;
  personJuridic: PersonJuridic;
  address: Address;

  setPersonFisic: React.Dispatch<React.SetStateAction<PersonFisic>>;
  setPersonJuridic: React.Dispatch<React.SetStateAction<PersonJuridic>>;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
}

const DoctorpayContext = React.createContext({} as DoctorpayContextInterface);

export const DoctorpayProvider: React.FC = ({ children }) => {
  const [personFisic, setPersonFisic] = React.useState<PersonFisic>({
    name: '',
    birthDate: '',
    cpf: '',
    rg: '',
    ocupationArea: '',
    email: '',
    phone: '',
  });

  const [personJuridic, setPersonJuridic] = React.useState<PersonJuridic>({
    name: '',
    birthDate: '',
    cpf: '',
    rg: '',
    ocupationArea: '',
    email: '',
    phone: '',
    businessType: '',
    cnpj: '',
    reasonSocial: '',
  });

  const [address, setAddress] = React.useState<Address>({
    cep: '',
    city: '',
    complement: '',
    isNotNumber: false,
    neighborhod: '',
    number: 0,
    street: '',
    uf: '',
  });

  return (
    <DoctorpayContext.Provider
      value={{
        personFisic,
        personJuridic,
        address,
        setPersonFisic,
        setPersonJuridic,
        setAddress,
      }}
    >
      {children}
    </DoctorpayContext.Provider>
  );
};

export default DoctorpayContext;
