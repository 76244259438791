import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  position: fixed;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;

  padding: 20px 18px 20px 36px;
  max-height: 64px;
  z-index: 8;

  @media (min-width: 768px) {
    max-height: 96px;
    padding: 24px 6% 22px 6%;

    svg {
      width: 123.02px;
      height: 24px;

      @media (min-width: 768px) {
        width: 164.03px;
        height: 32px;
      }
    }
  }

  @media (min-width: 1366px) {
    max-height: 95px;
  }
`;

export const ContainerList = styled.ul`
  display: none;

  @media (min-width: 768px) {
    padding: 0px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
  }
`;

export const ListItem = styled.li`
  margin-bottom: 48px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

interface ButtonNavProps {
  checked: boolean;
}
export const ButtonNav = styled.button<ButtonNavProps>`
  cursor: pointer;
  border: none;
  min-width: 146px;
  max-height: 50px;
  width: auto;
  display: flex;
  padding: 14px 0px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transform-origin: center;
  font-size: 16px;
  line-height: 21px;
  color: ${props => (props.checked ? '#fff' : '#1A1818')};
  background-color: ${props => (props.checked ? '#FC5166' : '#fff')};

  @media (min-width: 768px) {
    margin-left: 24px;
  }

  @media (min-width: 1366px) {
    max-height: 56px;
    font-size: 20px;
    line-height: 27px;
    padding: 16px 32px;
    width: 184px;
  }
`;

export const ListMobile = styled.article<CrossProps>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  padding-top: 64px;
  background-color: #fff;
  transition: 0.5s all;
  transform-origin: top;
  z-index: 6;

  ${props => !props.active && 'transform: translateY(-120vh);'}

  @media(min-width: 768px) {
    display: none;
  }
`;

export const ListMobileButton = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0px;
  list-style-type: none;
  margin: auto;

  @media (min-width: 768px) {
    display: none;
  }
`;

interface CrossProps {
  active: boolean;
}
export const Cross = styled.button<CrossProps>`
  width: 64px;
  height: 64px;
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: transparent;

  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    top: 28px;
    right: 18px;
    border-radius: 1px;
    background-color: #1a1818;
    transition: all 0.5s ease-in-out;
    ${props => props.active && 'transform: rotate(-45deg);'}
  }

  ::after {
    ${props =>
      props.active
        ? 'transform: rotate(45deg);'
        : 'transform: translateY(6px);'}
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const LogoButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
`;

export const TabBar = styled.section`
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 21px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 21px;
  z-index: 5;

  @media (min-width: 768px) {
    display: none;
  }
`;

interface ButtonTabProps {
  buttonType: 'primary' | 'secondary';
}
export const ButtonTab = styled.button<ButtonTabProps>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: ${props =>
    props.buttonType === 'primary' ? '#FC5166' : '#FFF'};
  color: ${props => (props.buttonType !== 'primary' ? '#FC5166' : '#FFF')};
  width: 50%;
  height: 21px;
  padding: 22px 0px 21px 0px;

  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
`;
