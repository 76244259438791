import styled from 'styled-components';

export const ContentList = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 22px;

  @media (min-width: 768px) {
    max-width: 260px;
    margin-bottom: 0px;
  }
`;

export const ContentNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 2px solid #fc5166;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    width: 72px;
    height: 72px;
    border-radius: 72px;
    margin-bottom: 24px;
  }
`;

export const TextNumber = styled.h3`
  font-size: 20px;
  line-height: 27px;
  color: #fc5166;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const ItemTitle = styled.h5`
  color: #1a1818;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 6px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ItemDescription = styled.p`
  color: #727882;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
