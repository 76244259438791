import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonLarge } from '../../../../components/Button';
import {
  ContentList,
  ContentNumber,
  Item,
  ItemDescription,
  ItemTitle,
  TextNumber,
} from './styles';

const itens: any[] = [
  {
    title: 'Você se cadastra aqui no site',
    description:
      'Tenha em mão seus documentos pessoais e os documentos do se negócio.',
  },
  {
    title: 'Te entregamos a maquininha',
    description:
      'Após aprovação, os representantes da doctorpay  farão uma visita.',
  },
  {
    title: 'E começa a receber com doctorpay!',
    description:
      'É só baixar o app para receber e gerenciar todos seus pagamentos.',
  },
];

const ItemList: React.FC = () => {
  const navigation = useNavigate();

  return (
    <>
      <ContentList>
        {itens.map((item: any, index: number) => {
          return (
            <Item key={item.title}>
              <ContentNumber>
                <TextNumber>{index + 1}</TextNumber>
              </ContentNumber>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemDescription>{item.description}</ItemDescription>
            </Item>
          );
        })}
      </ContentList>

      <ButtonLarge
        text="Começar agora!"
        color="primary"
        onClick={() => navigation('/register')}
      />
    </>
  );
};

export { ItemList };
