import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 60px 36px;

  @media (min-width: 768px) {
    padding: 100px 6%;
  }

  @media (min-width: 1366px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;

  button {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }
`;

export const Title = styled.h1`
  color: #1a1818;
  font-size: 32px;
  line-height: 38px;

  @media (min-width: 768px) {
    width: 80%;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 27px;
  }

  @media (min-width: 1366px) {
    width: 55%;
  }
`;

export const TitleSpan = styled.span`
  color: #fc5166;
`;

export const ContentList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  margin-top: 21px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    overflow-x: scroll;
    margin-top: 77px;
  }

  @media (min-width: 1366px) {
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 0px;
  }
`;

export const ItemList = styled.li`
  display: flex;
  flex-direction: column;
  padding: 24px 40px 28px 40px;
  border-radius: 48px;
  width: 100%;
  border: 2px solid #dfe9f7;
  margin-bottom: 16px;

  svg {
    height: 56px;
  }

  @media (min-width: 768px) {
    max-width: 260px;
    min-width: 260px;
    max-height: 295px;
    padding: 56px 40px 47px 40px;
    margin-right: 16px;
    margin-bottom: 0px;

    svg {
      height: 72px;
    }
  }

  @media (min-width: 1366px) {
    margin-bottom: 16px;
  }
`;

export const ItemText = styled.h3`
  color: #1a1818;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-top: 24px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
