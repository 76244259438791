import React from 'react';

const Facebook: React.FC = () => {
  return (
    <svg
      id="IconFacebook"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Retângulo_5783"
        data-name="Retângulo 5783"
        width="32"
        height="32"
        fill="none"
        opacity="0"
      />
      <path
        id="Facebook-Logo.wine"
        d="M9.308,28H3.772V14.2H0V9.7H3.771v-3.2C3.771,2.718,5.44,0,10.961,0c1.168,0,3,.235,3,.235V4.408H12.037c-1.962,0-2.728.6-2.728,2.247V9.7h4.581L13.481,14.2H9.308Z"
        transform="translate(9.019 2)"
        fill="#727882"
      />
    </svg>
  );
};

export { Facebook };
