import styled from 'styled-components';

export const Label = styled.label`
  color: #1a1818;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-top: 24px;
  position: relative;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;
  }
`;

export const ContainerTextArea = styled.div`
  position: relative;
  display: flex;
`;

export const TextareaContent = styled.textarea`
  color: #fc5166;
  -webkit-text-fill-color: #1a1818;
  margin-top: 7px;
  border: 2px solid #e5ecf8;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
  padding: 15px 96px 15px 17px;
  width: 100%;
  height: 91px;
  resize: none;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 96px 12px 24px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-input-placeholder {
    -webkit-text-fill-color: #9aa1b3;
  }

  :disabled {
    background-color: #f5f8fd;
    -webkit-text-fill-color: #979eaf;

    ::-webkit-input-placeholder {
      -webkit-text-fill-color: #979eaf;
    }
  }
`;

interface ContentAbosluteProps {
  disabled?: boolean;
}
export const ContentAboslute = styled.div<ContentAbosluteProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  right: 12px;

  svg path {
    fill: ${props => (props.disabled ? '#979EAF' : '#1a1818')};
  }
`;

export const CountyText = styled.p`
  margin: 15px 12px 21px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9aa1b3;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin: 12px 12px 22px 0px;
  }
`;

export const TextError = styled.p`
  position: absolute;
  right: 0;
  top: calc(100% + 6px);
  color: #fc5166;
`;
