import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonLarge } from '../../../components/Button';
import {
  CheckCircle,
  CircleTwelve,
  CirclePixAnexoQr,
  CirlcleCash,
} from '../../../components/Icons';
import {
  Container,
  ContentList,
  ContentTitle,
  ItemList,
  ItemText,
  Title,
  TitleSpan,
} from './styles';

const Description: React.FC = () => {
  const itens: any[] = [
    { text: 'Taxa zero, sem aluguel de maquininha.', icon: <CheckCircle /> },
    { text: 'Crie e gerencie links de pagamento', icon: <CirclePixAnexoQr /> },
    { text: 'Parcele em 12x e não pague por isso.', icon: <CircleTwelve /> },
    { text: 'Receba rápido em sua conta', icon: <CirlcleCash /> },
  ];

  const navigation = useNavigate();

  return (
    <Container>
      <ContentTitle>
        <Title>
          Tudo o que você precisa e um pouco
          <TitleSpan> mais.</TitleSpan>
        </Title>
        <ButtonLarge
          text="Começar agora!"
          color="primary"
          onClick={() => navigation('/register')}
        />
      </ContentTitle>

      <ContentList>
        {itens.map(item => {
          return (
            <ItemList key={item.text}>
              {item.icon}
              <ItemText>{item.text}</ItemText>
            </ItemList>
          );
        })}
      </ContentList>
    </Container>
  );
};

export { Description };
