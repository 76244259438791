import React from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

import { Label } from './styles';

interface Props extends CurrencyInputProps {
  title: string;
}

const InputCurrency: React.FC<Props> = ({ title, ...rest }) => {
  return (
    <Label>
      {title}

      <CurrencyInput decimalsLimit={2} min={0} {...rest} />
    </Label>
  );
};

export { InputCurrency };
