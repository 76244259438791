import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 58px 0px 60px 0px;

  @media (min-width: 768px) {
    padding: 120px 0px 80px 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 36px;

  @media (min-width: 768px) {
    padding: 0px 6%;
  }
`;

export const Title = styled.h1`
  color: #1a1818;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 11px;

  @media (min-width: 768px) {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 9px;
  }
`;

export const Text = styled.p`
  color: #727882;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 38px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 77px;
  }
`;

export const TextSpan = styled.span`
  color: #fc5166;
`;

export const ContentRating = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 117px;
  }

  @media (min-width: 1366px) {
    margin-top: 197px;
  }
`;

export const SubTitle = styled.h2`
  color: #1a1818;
  font-size: 32px;
  line-height: 38px;
  padding: 0px 36px 37px 36px;

  @media (min-width: 768px) {
    padding: 0px 6% 43px 6%;
  }
`;

export const ContentRates = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  padding: 0px 36px;

  @media (min-width: 768px) {
    padding: 0px 6%;
  }
`;
