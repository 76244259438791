import React from 'react';

const Instagram: React.FC = () => {
  return (
    <svg
      id="IconInstagram"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Retângulo_5783"
        data-name="Retângulo 5783"
        width="32"
        height="32"
        fill="none"
        opacity="0"
      />
      <g id="Instagram-Glyph-Black-Logo.wine" transform="translate(2 2)">
        <path
          id="Caminho_57110"
          data-name="Caminho 57110"
          d="M14,0C10.2,0,9.721.016,8.228.084a10.277,10.277,0,0,0-3.4.651A6.863,6.863,0,0,0,2.35,2.35,6.862,6.862,0,0,0,.735,4.829a10.277,10.277,0,0,0-.651,3.4C.016,9.721,0,10.2,0,14s.016,4.279.084,5.772a10.277,10.277,0,0,0,.651,3.4A6.863,6.863,0,0,0,2.35,25.65a6.863,6.863,0,0,0,2.48,1.615,10.278,10.278,0,0,0,3.4.651C9.721,27.984,10.2,28,14,28s4.279-.016,5.772-.084a10.277,10.277,0,0,0,3.4-.651,7.159,7.159,0,0,0,4.095-4.095,10.277,10.277,0,0,0,.651-3.4C27.984,18.279,28,17.8,28,14s-.016-4.279-.084-5.772a10.277,10.277,0,0,0-.651-3.4A6.863,6.863,0,0,0,25.65,2.35,6.863,6.863,0,0,0,23.171.735a10.277,10.277,0,0,0-3.4-.651C18.279.016,17.8,0,14,0Zm0,2.523c3.738,0,4.181.014,5.657.082a7.745,7.745,0,0,1,2.6.482,4.337,4.337,0,0,1,1.61,1.047,4.338,4.338,0,0,1,1.047,1.61,7.745,7.745,0,0,1,.482,2.6c.067,1.476.082,1.919.082,5.657s-.014,4.181-.082,5.657a7.744,7.744,0,0,1-.482,2.6,4.637,4.637,0,0,1-2.657,2.657,7.745,7.745,0,0,1-2.6.482c-1.476.067-1.919.082-5.657.082s-4.181-.014-5.657-.082a7.744,7.744,0,0,1-2.6-.482,4.337,4.337,0,0,1-1.61-1.047,4.338,4.338,0,0,1-1.047-1.61,7.746,7.746,0,0,1-.482-2.6c-.067-1.476-.082-1.919-.082-5.657s.014-4.181.082-5.657a7.746,7.746,0,0,1,.482-2.6,4.338,4.338,0,0,1,1.047-1.61,4.338,4.338,0,0,1,1.61-1.047,7.745,7.745,0,0,1,2.6-.482c1.476-.067,1.919-.082,5.657-.082"
          fill="#727882"
        />
        <path
          id="Caminho_57111"
          data-name="Caminho 57111"
          d="M170.6,130.1a4.667,4.667,0,1,1,4.667-4.667A4.667,4.667,0,0,1,170.6,130.1Zm0-11.856a7.189,7.189,0,1,0,7.189,7.189,7.189,7.189,0,0,0-7.189-7.189m9.153-.284a1.68,1.68,0,1,1-1.68-1.68,1.68,1.68,0,0,1,1.68,1.68"
          transform="translate(-156.597 -111.438)"
          fill="#727882"
        />
      </g>
    </svg>
  );
};

export { Instagram };
