import React from 'react';

import { useNavigate } from 'react-router-dom';

import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import Carousel1 from '../../../assets/Carousel1.png';
import Carousel2 from '../../../assets/Carousel2.png';
import Carousel3 from '../../../assets/Carousel3.png';

import '@brainhubeu/react-carousel/lib/style.css';

import { ButtonLarge } from '../../../components/Button';
import {
  Container,
  ContentCarrousel,
  Title,
  Scope,
  BackgorundContent,
  ButtonContent,
  ContentDoor,
  Door,
} from './styles';

interface Props {
  bodyRef?: any;
}

const Carrousel: React.FC<Props> = ({ bodyRef }) => {
  const [itemSlide, setItemSlide] = React.useState<number>(0);

  const navigation = useNavigate();

  const slides: any[] = [
    {
      text: 'Diversifique suas formas de receber e não pague nada por isso!',
      image: Carousel1,
    },
    { text: 'Aumente sua receita recebendo com cartão', image: Carousel2 },
    { text: 'Seu cliente pode pagar de onde estiver', image: Carousel3 },
  ];

  return (
    <Container>
      <Carousel
        value={itemSlide}
        onChange={() => setItemSlide(itemSlide + 1)}
        plugins={[
          'infinite',
          {
            resolve: autoplayPlugin,
            options: {
              interval: 7000,
            },
          },
        ]}
        animationSpeed={1000}
        slides={slides.map((slide: any) => (
          <Scope key={slide.text}>
            <ContentCarrousel
              style={{ backgroundImage: `url(${slide.image})` }}
            />
            <BackgorundContent>
              <Title>{slide.text}</Title>

              <ButtonContent>
                <ButtonLarge
                  text="Começar agora!"
                  color="primary"
                  onClick={() => navigation('/register')}
                />
                <ButtonLarge
                  style={{
                    marginLeft: 12,
                    color: '#FFF',
                    borderColor: '#FFF',
                    backgroundColor: 'transparent',
                  }}
                  text="Simule"
                  color="secondary"
                  onClick={() =>
                    window.scroll({
                      top: bodyRef.current.children[0].clientHeight,
                      behavior: 'smooth',
                    })
                  }
                />
              </ButtonContent>
            </BackgorundContent>
          </Scope>
        ))}
      />

      <ContentDoor>
        <Door
          slide={
            itemSlide % 3 === 0 ? 'one' : itemSlide % 3 === 1 ? 'two' : 'tree'
          }
        />
      </ContentDoor>
    </Container>
  );
};

export { Carrousel };
