import React from 'react';

import {
  Container,
  SubTitle,
  Title,
  ContentTitle,
  Column,
  ContentStar,
  Description,
  ImageAvatar,
} from './styles';

const CardRate: React.FC = () => {
  return (
    <Container>
      <ContentTitle>
        <ImageAvatar src="https://socientifica.com.br/wp-content/uploads/2020/02/bebe-ornitorrinco.jpg" />
        <Column>
          <Title>Dra. Maria da Silva</Title>
          <SubTitle>CRM 00000000-0/BR</SubTitle>
        </Column>
      </ContentTitle>
      <Description>
        “A maquininha Doctorpay aumentou a minha carteira de clientes e ajudou
        muitos que precisaram principalmente durante esta fase de pandemia”
      </Description>
    </Container>
  );
};

export { CardRate };
