import React from 'react';

import { ContainerRadio, ContentRadio, TextRadio, RadioCheck } from './styles';

interface Props {
  text: string;
  value: string;
  actualValue: string;
  setValue: any;
}

const Radio: React.FC<Props> = ({ text, value, actualValue, setValue }) => {
  return (
    <ContainerRadio onClick={() => setValue(value)}>
      <ContentRadio active={value === actualValue}>
        <RadioCheck active={value === actualValue} />
      </ContentRadio>
      <TextRadio>{text}</TextRadio>
    </ContainerRadio>
  );
};

export { Radio };
