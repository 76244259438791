import React from 'react';
import Input from 'react-input-mask';

import { Label, TextError } from './styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  mask: string;
  error?: string;
}

const InputMask: React.FC<Props> = ({ title, mask, error, ...rest }) => {
  return (
    <Label>
      {title}

      <Input mask={mask} {...rest} />

      {error && <TextError>{error}</TextError>}
    </Label>
  );
};

export { InputMask };
