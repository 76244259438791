import styled from 'styled-components';

export const Label = styled.div`
  color: #1a1818;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-top: 24px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding-left: 16px;
  border: 2px solid #e5ecf8;
  border-radius: 8px;
  margin-top: 7px;

  @media (min-width: 768px) {
    padding: 6px;
    padding-left: 24px;
  }
`;

export const Scope = styled.div`
  display: flex;
`;

export const ButtonSpin = styled.button`
  cursor: pointer;
  display: flex;
  border: 0px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  margin-left: 6px;

  @media (min-width: 768px) {
    padding: 24px;
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: #fc5166;
    }
  }

  :hover,
  :focus {
    background-color: #feecee;
  }

  :disabled {
    cursor: default;
    background-color: #fff;

    svg path {
      fill: #cfd5e2;
    }
  }
`;

export const SpinText = styled.p`
  color: #1a1818;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
