import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 72px 36px 80px 36px;

  @media (min-width: 768px) {
    align-items: center;
  }

  @media (min-width: 1366px) {
    flex-direction: row;
    align-items: flex-start;
    padding: 110px 6% 80px 6%;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 644px;

  @media (min-width: 1366px) {
    max-width: 41%;
  }
`;

export const Title = styled.h2`
  color: #1a1818;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 9px;

  @media (min-width: 768px) {
    margin-bottom: 5px;
  }
`;

export const SubText = styled.p`
  color: #727882;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 768px) {
    max-width: 352px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 37px;
  }
`;

export const LinkItem = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 16px;
  border-radius: 16px;
  transition: 0.5s all;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media (min-width: 1366px) {
    width: 352px;
  }

  :hover {
    background-color: #f2f6ff;

    @media (min-width: 768px) {
      margin-right: 16%;
    }

    @media (min-width: 1366px) {
      width: 100%;
    }
  }

  svg {
    :nth-child(2) {
      transform: rotateZ(-90deg);
      path {
        fill: #1a1818;
      }
    }
  }
`;

export const ContentLink = styled.div`
  display: flex;
  width: 80%;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
`;

export const LinkText = styled.p`
  color: #1a1818;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }
`;

export const Hr = styled.hr`
  border: 0.5px solid #dfe9f7;
  margin: 12px 0px;
  width: 100%;
  padding: 0px;

  @media (min-width: 768px) {
    margin: 8px 0px;
  }

  @media (min-width: 1366px) {
    width: 352px;
  }
`;

export const Scope = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
