import styled from 'styled-components';

export const Container = styled.label`
  width: 18px;
  height: 18px;
  position: relative;
`;

export const Input = styled.section<{ checked?: boolean }>`
  cursor: pointer;
  width: 0px;
  height: 0px;
  transition: 1s all;

  transition: 1s all;
  :before {
    content: '';
    display: flex;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border-radius: 6px;
    border: 2px solid ${props => (props.checked ? '#fc5166' : '#979eaf')};
    background-color: ${props => (props.checked ? '#fc5166' : 'transparent')};
  }

  ${props =>
    props.checked &&
    `:after {
    content: '';
    display: flex;
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    background-color: transparent;
    top: 3px;
    left: 7px;
    transition: 1s all;
  }`}

  @media (min-width: 768px) {
    :before {
      width: 24px;
      height: 24px;
      border-radius: 8px;
    }

    ${props =>
      props.checked &&
      `:after {
      width: 8px;
      height: 14px;
      left: 8px;
      top: 3px;
      border-width: 0px 2px 2px 0;
    }`}
  }
`;
