import React from 'react';

import { Chevron } from '../../../../components/Icons';
import {
  ContentItem,
  ItemButton,
  ContentDescription,
  Description,
} from './styles';

const ItemMenu: React.FC = () => {
  const [active, setActive] = React.useState<boolean>(false);

  return (
    <ContentItem>
      <ItemButton onClick={() => setActive(!active)} active={active}>
        Como uso o simulador?
        <Chevron />
      </ItemButton>

      <ContentDescription active={active}>
        <Description>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </Description>
      </ContentDescription>
    </ContentItem>
  );
};

export { ItemMenu };
