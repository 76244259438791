import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 644px;
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 140px;
  }

  @media (min-width: 1366px) {
    margin-top: 0px;
    max-width: 58%;
  }

  button {
    margin-top: 32px;

    @media (min-width: 768px) {
      margin-top: 24px;
    }
  }
`;

export const Title = styled.h3`
  color: #1a1818;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const Span = styled.span`
  color: #fc5166;
`;

export const SubText = styled.p`
  color: #727882;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 27px;

  @media (min-width: 768px) {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    label {
      width: 48%;
    }
  }
`;

export const SuccessMessage = styled.span<{ visible: boolean }>`
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 16px;
  border-radius: 8px;
  color: #ffffff;
  background-color: green;
  transition: 1s all;
  transform: translateX(${props => (props.visible ? 0 : 100)}vh);
`;
