import React from 'react';

const Location: React.FC = () => {
  return (
    <svg
      id="IconPin"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Retângulo_1563"
        data-name="Retângulo 1563"
        width="32"
        height="32"
        fill="none"
        opacity="0"
      />
      <path
        id="Caminho_57120"
        data-name="Caminho 57120"
        d="M19.97,4.911A9.941,9.941,0,1,0,5.911,18.97L12.941,26,19.97,18.97a9.941,9.941,0,0,0,0-14.059Zm-7.029,9.87a2.84,2.84,0,1,1,2.84-2.84A2.84,2.84,0,0,1,12.941,14.781Z"
        transform="translate(3.001 2)"
        fill="#1a1818"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { Location };
