import React from 'react';

const CirclePixAnexoQr: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="76"
      viewBox="0 0 186 76"
    >
      <g
        id="Grupo_19421"
        data-name="Grupo 19421"
        transform="translate(11437 12969)"
      >
        <path
          id="Caminho_57116"
          data-name="Caminho 57116"
          d="M36,0A36,36,0,1,1,0,36,36,36,0,0,1,36,0Z"
          transform="translate(-11437 -12967)"
          fill="#1082ff"
        />
        <g id="IconLink" transform="translate(-11417 -12947)">
          <rect
            id="Retângulo_1563"
            data-name="Retângulo 1563"
            width="32"
            height="32"
            fill="none"
            opacity="0"
          />
          <path
            id="Caminho_57057"
            data-name="Caminho 57057"
            d="M764.393,835.787a6.663,6.663,0,0,1-4.632-11.458l5.318-5.317a6.675,6.675,0,0,1,9.428,0,1.333,1.333,0,1,1-1.887,1.885,4.007,4.007,0,0,0-5.656,0l-5.333,5.334A4,4,0,1,0,767.171,832l1.581-1.581a1.333,1.333,0,0,1,1.886,1.885l-1.612,1.612A6.652,6.652,0,0,1,764.393,835.787Zm8.063-8.066a6.643,6.643,0,0,1-4.713-1.949,1.333,1.333,0,0,1,1.885-1.885,4.011,4.011,0,0,0,5.658,0l5.333-5.334a4,4,0,1,0-5.543-5.771l-.114.114-1.466,1.467a1.334,1.334,0,0,1-1.885-1.887l1.626-1.623a6.667,6.667,0,0,1,9.252,9.6l-5.317,5.317A6.648,6.648,0,0,1,772.455,827.721Z"
            transform="translate(-755.064 -806.328)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Grupo_19422"
        data-name="Grupo 19422"
        transform="translate(11437 12969)"
      >
        <g
          id="Elipse_318"
          data-name="Elipse 318"
          transform="translate(-11381 -12967)"
          fill="#1082ff"
          stroke="#fff"
          strokeWidth="2"
        >
          <circle cx="36" cy="36" r="36" stroke="none" />
          <circle cx="36" cy="36" r="37" fill="none" />
        </g>
        <g id="IconQR" transform="translate(-11361 -12947)">
          <rect
            id="Retângulo_1563-2"
            data-name="Retângulo 1563"
            width="32"
            height="32"
            fill="none"
            opacity="0"
          />
          <path
            id="Caminho_57103"
            data-name="Caminho 57103"
            d="M62.094,62.854H56.76a1.333,1.333,0,1,1,0-2.667h5.333a1.333,1.333,0,0,1,0,2.667Zm-10.667,0a1.334,1.334,0,0,1-1.333-1.333V56.187a1.334,1.334,0,0,1,1.333-1.333h2.667a1.333,1.333,0,1,1,0,2.667H52.76v4A1.334,1.334,0,0,1,51.427,62.854Zm-6.667,0H42.094a2.669,2.669,0,0,1-2.667-2.667V57.521a2.669,2.669,0,0,1,2.667-2.667H44.76a2.669,2.669,0,0,1,2.667,2.667v2.667A2.669,2.669,0,0,1,44.76,62.854Zm-2.667-5.333v2.667H44.76V57.521Zm20,0H59.427a1.333,1.333,0,1,1,0-2.667h2.667a1.333,1.333,0,1,1,0,2.667Zm.015-5.333a1.333,1.333,0,1,1-.015-2.667h.015a1.333,1.333,0,1,1,0,2.667Zm-5.335,0H51.427a1.334,1.334,0,0,1-1.333-1.333v-4a1.333,1.333,0,1,1,2.667,0v2.667h4.013a1.333,1.333,0,1,1,0,2.667Zm-10.68,0H40.76a1.333,1.333,0,1,1,0-2.667h5.333a1.333,1.333,0,1,1,0,2.667ZM60.76,46.854H58.094a2.669,2.669,0,0,1-2.667-2.667V41.521a2.669,2.669,0,0,1,2.667-2.667H60.76a2.67,2.67,0,0,1,2.667,2.667v2.667A2.67,2.67,0,0,1,60.76,46.854Zm-2.667-5.333v2.667H60.76V41.521ZM44.76,46.854H42.094a2.669,2.669,0,0,1-2.667-2.667V41.521a2.669,2.669,0,0,1,2.667-2.667H44.76a2.669,2.669,0,0,1,2.667,2.667v2.667A2.669,2.669,0,0,1,44.76,46.854Zm-2.667-5.333v2.667H44.76V41.521Zm9.333,1.333a1.334,1.334,0,0,1-1.333-1.333V40.187a1.333,1.333,0,0,1,2.667,0v1.333A1.334,1.334,0,0,1,51.427,42.854Z"
            transform="translate(-35.428 -34.853)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Grupo_19423"
        data-name="Grupo 19423"
        transform="translate(11437 12969)"
      >
        <g
          id="Elipse_319"
          data-name="Elipse 319"
          transform="translate(-11325 -12967)"
          fill="#1082ff"
          stroke="#fff"
          strokeWidth="2"
        >
          <circle cx="36" cy="36" r="36" stroke="none" />
          <circle cx="36" cy="36" r="37" fill="none" />
        </g>
        <g id="Icon-Pix" transform="translate(-11305 -12947)">
          <path
            id="Caminho_50703"
            data-name="Caminho 50703"
            d="M293.777,155.786l-8.016-8.016a5.331,5.331,0,0,0-7.536,0l-8.015,8.016a5.328,5.328,0,0,0,0,7.536l8.015,8.015a5.327,5.327,0,0,0,7.536,0l8.016-8.015A5.33,5.33,0,0,0,293.777,155.786Zm-13.665-6.131a2.664,2.664,0,0,1,3.764,0l2.958,2.957a3.667,3.667,0,0,0-1.911,1.015L282.545,156a.774.774,0,0,1-1.053,0l-2.391-2.391a3.656,3.656,0,0,0-1.936-1.015Zm3.764,19.8a2.661,2.661,0,0,1-3.764,0l-2.947-2.947a3.652,3.652,0,0,0,1.937-1.015l2.388-2.387a.735.735,0,0,1,.527-.216h0a.737.737,0,0,1,.527.215l2.379,2.377a3.662,3.662,0,0,0,1.911,1.015Zm8.016-8.016L289.43,163.9h-1.895a1.024,1.024,0,0,1-.728-.3l-2.38-2.379a3.382,3.382,0,0,0-2.408-.995h-.005a3.388,3.388,0,0,0-2.408.995l-2.389,2.389a1.016,1.016,0,0,1-.723.3h-1.931l-2.468-2.468a2.665,2.665,0,0,1,0-3.764l2.468-2.468h1.931a1.021,1.021,0,0,1,.723.3l2.393,2.393a3.415,3.415,0,0,0,4.819-.005l2.379-2.377a1.021,1.021,0,0,1,.728-.3h1.895l2.461,2.46a2.664,2.664,0,0,1,0,3.764Z"
            transform="translate(-265.982 -143.545)"
            fill="#fff"
          />
          <rect
            id="Retângulo_150"
            data-name="Retângulo 150"
            width="32"
            height="32"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export { CirclePixAnexoQr };
