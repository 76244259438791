import React from 'react';

import { ButtonSmall } from '../../../../components/Button';
import { Input, Textarea, InputMask } from '../../../../components/Form';
import services from '../../../../services';
import {
  FormContainer,
  Title,
  SubText,
  Row,
  Span,
  SuccessMessage,
} from './styles';

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
  city: string;
  state: string;
  message: string;
  recaptcha: boolean;
}

const DDD = [
  11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
  37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
  65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
  89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
];

const verifyPhoneNumber = (value: string) => {
  const findDDD = DDD.find(ddd => ddd === Number(value.substring(0, 2)));

  console.log(value);

  if (value === '00000000000') {
    return false;
  }

  return !!findDDD;
};

const regEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

const Form: React.FC = () => {
  const [button, setButton] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<FormData>({
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    state: '',
    message: '',
    recaptcha: false,
  });
  const [formError, setFormError] = React.useState<FormData>();
  const [success, setSuccess] = React.useState<boolean>(false);

  const verifyErrors = () => {
    let error: any;

    form.name.split(' ')?.length <= 1 &&
      (error = { ...error, name: 'Informe um nome completo' });
    form.city?.length <= 0 &&
      (error = { ...error, city: 'Informe uma cidade' });
    form.state?.length <= 0 &&
      (error = { ...error, state: 'Informe um estado' });
    form.message?.length <= 0 &&
      (error = { ...error, message: 'Informe uma mensagem' });
    !regEmail.test(form.email) &&
      (error = { ...error, email: 'Informe um email válido' });

    !verifyPhoneNumber(form.phoneNumber.replace(/\D/g, '')) &&
      (error = { ...error, phoneNumber: 'Informe um telefone válido' });

    setButton(!!error);
    setFormError(error);

    return !!error;
  };

  const submitForm = async () => {
    if (verifyErrors()) {
      return;
    }

    const { res }: any = await services.doctorpay.contact({
      city: form.city,
      email: form.email,
      message: form.message,
      name: form.name,
      state: form.state,
      telephone: form.phoneNumber,
    });

    if (res) {
      setSuccess(true);
      setTimeout(() => setSuccess(false), 6000);
    }
  };

  React.useEffect(() => setButton(false), [form]);

  return (
    <FormContainer onSubmit={submit => submit.preventDefault()}>
      <Title>
        Deixe sua <Span>mensagem</Span>
      </Title>
      <SubText>Retornaremos pelo e-mail informado.</SubText>

      <Input
        title="Seu nome"
        placeholder="Nome"
        type="text"
        value={form.name}
        error={formError?.name}
        onChange={input =>
          setForm({ ...form, name: input.target.value.replace(/\d/g, '') })
        }
      />

      <Row>
        <Input
          type="email"
          error={formError?.email}
          title="Seu melhor e-mail"
          placeholder="E-mail"
          onChange={input => setForm({ ...form, email: input.target.value })}
        />

        <InputMask
          type="tel"
          error={formError?.phoneNumber}
          title="Telefone"
          placeholder="DDD + Número"
          onChange={input =>
            setForm({ ...form, phoneNumber: input.target.value })
          }
          mask="(99) 99999-9999"
        />
      </Row>
      <Row>
        <Input
          title="Cidade"
          type="text"
          placeholder="Cidade"
          error={formError?.city}
          value={form.city}
          onChange={input =>
            setForm({ ...form, city: input.target.value.replace(/\d/g, '') })
          }
        />
        <Input
          title="Estado"
          placeholder="Estado"
          error={formError?.state}
          type="text"
          value={form.state}
          onChange={input =>
            setForm({
              ...form,
              state: input.target.value.replace(/\d/g, ''),
            })
          }
        />
      </Row>

      <Textarea
        title="Digite sua mensagem"
        placeholder="Mensagem..."
        length={form.message.length}
        maxLength={1000}
        value={form.message}
        error={formError?.message}
        onChange={input =>
          setForm({ ...form, message: input.target.value.replace('\n', '') })
        }
      />

      <ButtonSmall
        text="Enviar"
        color="primary"
        disabled={button}
        onClick={() => submitForm()}
      />

      <SuccessMessage visible={success}>
        Seus dados foram enviados com sucesso !
      </SuccessMessage>
    </FormContainer>
  );
};

export { Form };
