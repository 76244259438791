import { AxiosInstance } from 'axios';

interface simulationSend {
  paymentType: 'pix';
  paymentForm: string;
  value: number;
  numberInstallments: number;
}

export interface ResultData {
  installmentValue: number;
  numberInstallments: number;
  paymentForm: string;
  paymentType: string;
  totalValue: number;
  value: number;
}

interface simulationReceive {
  code: number;
  message: string;
  resultData: ResultData;
}

interface contactSend {
  name: string;
  email: string;
  telephone: string;
  city: string;
  state: string;
  message: string;
}

export default (api: AxiosInstance, viaCep: AxiosInstance) => ({
  simulation: async ({ ...data }: simulationSend) => {
    let res;
    let error;

    try {
      res = await api.post<simulationReceive>(`/doctorpay/api/payments`, {
        ...data,
      });
    } catch (err) {
      error = err;
    }

    return { res: res?.data, error };
  },

  contact: async ({ ...contact }: contactSend) => {
    let res;
    let error;

    try {
      res = await api.post(`/doctorpay/api/contacts`, {
        ...contact,
      });
    } catch (err) {
      error = err;
    }

    return { res: res?.data, error };
  },

  getCep: async ({ cep }: { cep: string }) => {
    let res;
    let error;

    try {
      res = await viaCep.get(`ws/${cep}/json/`);
    } catch (err) {
      error = err;
    }

    return { res: res?.data, error };
  },
});
