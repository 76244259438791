import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 48px 36px 30px 36px;
  background-color: #1a1818;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 80px 6% 22px 6%;
    align-items: flex-start;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  svg {
    width: 123.02px;
    height: 24px;
    margin-bottom: 32px;

    path,
    rect {
      fill: #fff;
    }

    @media (min-width: 768px) {
      width: 164.03px;
      height: 32px;
      margin-bottom: 24px;
    }
  }
`;

export const Text = styled.p`
  color: #727882;
  font-size: 14px;
  line-height: 21px;
`;

export const Br = styled.br``;

export const LinkExterno = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;
`;

export const RowStore = styled.div`
  display: flex;
  margin-top: 26px;

  @media (min-width: 768px) {
    margin-top: 22px;
  }
`;

export const ImageStore = styled.img`
  width: 135px;
  height: 40px;
  margin: 10px;
`;

export const RowSocial = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
`;

interface LinkSocialProps {
  social: 'facebook' | 'instagram' | 'twiter';
}

export const LinkSocial = styled.a<LinkSocialProps>`
  display: flex;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  margin-right: 12px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 72px;
    height: 72px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin: auto;
  }

  :hover {
    background-color: ${props =>
      props.social === 'facebook'
        ? '#1082ff'
        : props.social === 'instagram'
        ? '#ed55a1'
        : '#1da1f2'};
  }
`;

export const ListItem = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  margin-top: 2px;

  @media (min-width: 768px) {
    margin-top: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 54px;

  #item-title {
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-top: 0px;
      font-size: 20px;
      line-height: 27px;
    }
  }

  @media (min-width: 768px) {
    margin-top: 0px;
  }

  #span {
    color: #fff;
  }
`;

export const ItemList = styled.li`
  color: #727882;
  font-size: 14px;
  line-height: 21px;
  margin-top: 14px;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const LogoButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
`;
