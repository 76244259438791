import React from 'react';

import { useNavigate } from 'react-router-dom';
import MachineCard from '../../../assets/MachineCard.png';
import DesktopMobile from '../../../assets/DesktopMobile.png';

import {
  Br,
  CancelPrice,
  CardMachine,
  ColumnFlex,
  Container,
  ContentImage,
  DesktopImage,
  Price,
  RowFlex,
  Scope,
  SubText,
  Text,
  TextSpan,
} from './styles';
import { ButtonLarge, ButtonSmall } from '../../../components/Button';

const DescriptionTwo: React.FC = () => {
  const navigation = useNavigate();

  return (
    <Container>
      <RowFlex>
        <Scope>
          <Text>Maquininha doctorpay</Text>
          <CancelPrice>R$ 500,00</CancelPrice>
          <Price>R$ 500,00</Price>
          <ButtonSmall
            id="small"
            text="Pedir agora!"
            color="primary"
            onClick={() => navigation('/register')}
          />
          <ButtonLarge
            id="large"
            text="Pedir agora!"
            color="primary"
            onClick={() => navigation('/register')}
          />
        </Scope>
        <Scope>
          <CardMachine style={{ backgroundImage: `url(${MachineCard})` }} />
        </Scope>
      </RowFlex>

      <ColumnFlex>
        <Scope>
          <Text>
            Crie links de pagamento,
            <Br /> seu cliente paga <TextSpan>de onde estiver!</TextSpan>
          </Text>
        </Scope>
        <ContentImage>
          <DesktopImage src={DesktopMobile} alt="Tela da aplicação" />
        </ContentImage>
        <Scope>
          <SubText>
            Receba em até <TextSpan>1 dia útil</TextSpan> independente da forma
            que seu paciente pagar.
          </SubText>
          <ButtonSmall
            style={{ maxWidth: 136 }}
            text="Começar"
            color="primary"
            onClick={() => navigation('/register')}
          />
        </Scope>
      </ColumnFlex>
    </Container>
  );
};

export { DescriptionTwo };
