import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 32px;
  line-height: 38px;
  color: #1a1818;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 37px 36px 48px 36px;

  @media (min-width: 768px) {
    padding: 32px 6% 80px 6%;
    justify-content: center;
    align-items: center;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const ContentIcon = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 75px;
  width: 50%;

  @media (min-width: 768px) {
    min-height: 120px;
  }
`;
