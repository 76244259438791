import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  transition: 0.5s all;
`;

export const ContentCarrousel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 603px;
  height: 603px;
  background-size: cover;
  background-position: center;

  @media (min-width: 768px) {
    height: 672px;
    max-height: 672px;
  }
`;

export const BackgorundContent = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  padding: 120px 36px 129px 36px;
  max-height: 603px;
  height: 603px;
  background-color: #00000080;

  @media (min-width: 768px) {
    height: 672px;
    max-height: 672px;
    padding: 142px 6% 138px 6%;
  }

  @media (min-width: 1366px) {
    padding: 142px 6% 138px 6%;
  }
`;

export const Scope = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 48px;
  line-height: 58px;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 72px;
    width: 80%;
  }

  @media (min-width: 1024px) {
    width: 65%;
  }

  @media (min-width: 1366px) {
    width: 50%;
  }
`;

export const ButtonContent = styled.div`
  display: none;
  position: relative;
  z-index: 9;
  margin-top: 27px;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const ContentDoor = styled.div`
  display: none;
  position: absolute;
  width: 4px;
  height: 48px;
  border-radius: 2px;
  background-color: #a9a9aa;
  top: 50%;
  right: 32px;

  @media (min-width: 768px) {
    display: flex;
  }
`;

interface DoorProps {
  slide: 'one' | 'two' | 'tree';
}
export const Door = styled.div<DoorProps>`
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
  height: 16px;
  transition: 0.5s all;
  margin-top: ${props =>
    props.slide === 'one' ? 0 : props.slide === 'two' ? 16 : 32}px;
`;
