import React from 'react';

import { Button, Icon } from './styles';

export type colors = 'primary' | 'secondary';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: colors;
  text: string;
  onClick(): any;

  icon?: any;
  disabled?: boolean;
}

const ButtonLarge: React.FC<Props> = ({
  color,
  disabled,
  text,
  onClick,
  icon,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      backgroundColor={color}
      {...rest}
    >
      {icon && (
        <Icon disabled={disabled} backgroundColor={color}>
          {icon}
        </Icon>
      )}

      {text}
    </Button>
  );
};

export { ButtonLarge };
