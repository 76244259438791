import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f6ff;
  width: 100%;
  padding: 60px 36px;

  @media (min-width: 768px) {
    align-items: center;
    padding: 120px 14%;
  }

  @media (min-width: 1024px) {
    padding: 120px 20%;
  }

  @media (min-width: 1366px) {
    padding: 120px 10%;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  line-height: 38px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const TitleSpan = styled.span`
  color: #fc5166;
`;

export const Br = styled.br``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1366px) {
    flex-direction: row;
    //height: 364px;
  }
`;

export const Formulary = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 40px 32px 32px 32px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 40px 58px 48px 48px;
  }

  @media (min-width: 1366px) {
    max-width: 444px;
    padding: 40px 48px 48px 48px;
  }
`;

export const ResultContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (min-width: 1366px) {
    margin-left: 64px;
    margin-top: 0px;
    max-height: 344px;
    justify-content: space-between;
  }
`;

export const RowResult = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const ResultDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 58px;

  @media (min-width: 768px) {
    margin-top: 78px;
  }

  @media (min-width: 1366px) {
    margin-top: 0px;
  }
`;

export const TitleResult = styled.p`
  color: #1a1818;
  font-size: 16px;
  line-height: 21px;
  position: relative;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 36px;
  }

  svg {
    position: absolute;
    top: 100%;
    width: 20.62px;
    height: 37.21px;
  }
`;

export const SubPrice = styled.h5`
  margin-top: 34px;
  color: #727882;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;

  @media (min-width: 768px) {
    margin-top: 57.8px;
    font-size: 16px;
    line-height: 21px;
  }
`;

export const TextPrice = styled.h3`
  margin-top: 5px;
  color: #1a1818;
  font-size: 24px;
  line-height: 30px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const TextPriceSpan = styled.span`
  font-size: 20px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const NewPrice = styled.h1`
  color: #1a1818;
  margin-top: 21px;
  margin-bottom: 23px;
  font-size: 32px;
  line-height: 38px;

  @media (min-width: 768px) {
    margin-top: 54.5px;
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 58px;
  }
`;

export const CardContent = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 59.5px;
  }

  @media (min-width: 1366px) {
    margin-top: 0px;
  }
`;

export const TextCard = styled.p`
  color: #727882;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 11px;
`;

export const IconCards = styled.div`
  display: flex;

  svg {
    width: 38px;
    height: 24px;
    margin-right: 12px;
  }
`;

export const RadioContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;

  button {
    :nth-child(1) {
      margin-bottom: 24px;

      @media (min-width: 768px) {
        margin-bottom: 0px;
        margin-right: 162px;
      }

      @media (min-width: 1366px) {
        margin-right: 54px;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 8px;
  }
`;
