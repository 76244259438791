import React from 'react';

const CircleTwelve: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g
        id="Grupo_19424"
        data-name="Grupo 19424"
        transform="translate(-1008 -2576)"
      >
        <path
          id="Caminho_57116"
          data-name="Caminho 57116"
          d="M36,0A36,36,0,1,1,0,36,36,36,0,0,1,36,0Z"
          transform="translate(1008 2576)"
          fill="#8c38ff"
        />
        <path
          id="Caminho_57150"
          data-name="Caminho 57150"
          d="M1.368,13.416.516,10.548Q2.148,9.756,3.78,8.97T7.044,7.392l-.132,3.3L4.14,12.054ZM5.136,24V9.024L7.044,7.392,8.832,7.5V24Zm6.756,0V21.5q.66-.564,1.314-1.116t1.314-1.1q1.536-1.3,2.64-2.424a10.657,10.657,0,0,0,1.692-2.172,4.242,4.242,0,0,0,.588-2.1,2.212,2.212,0,0,0-.648-1.734,2.85,2.85,0,0,0-1.956-.582,5.573,5.573,0,0,0-1.158.126,7.66,7.66,0,0,0-1.194.36A11.869,11.869,0,0,0,12.132,12l-.324-3.528a12.2,12.2,0,0,1,1.218-.5q.666-.234,1.416-.42A14.514,14.514,0,0,1,15.99,7.26a11.932,11.932,0,0,1,1.6-.108,7.241,7.241,0,0,1,3.09.594,4.257,4.257,0,0,1,1.92,1.7,5.145,5.145,0,0,1,.654,2.658,6.46,6.46,0,0,1-.528,2.526,9.751,9.751,0,0,1-1.6,2.514,18.613,18.613,0,0,1-2.718,2.544l-2.364,1.86-.456-.72h7.86V24ZM25,24q.468-.84.828-1.452t.672-1.17q.312-.558.672-1.182l1.6-2.772.012.78-1.6-2.58q-.372-.6-.708-1.134t-.7-1.122q-.36-.588-.828-1.344l4-.108q.384.72.69,1.332t.654,1.3l.864,1.68h.264l.84-1.668q.336-.672.624-1.254t.636-1.278H37.56q-.5.792-.87,1.356T36,14.448q-.324.5-.72,1.128l-1.668,2.592L33.42,17.4l1.86,2.844q.588.888,1.134,1.716T37.74,24H33.468q-.348-.66-.63-1.206l-.63-1.218-.96-1.836h-.24l-.852,1.752q-.324.684-.6,1.242T28.932,24Z"
          transform="translate(1024.872 2596.424)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export { CircleTwelve };
