import React from 'react';

const CirlcleCash: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g
        id="Grupo_19425"
        data-name="Grupo 19425"
        transform="translate(11437 12653)"
      >
        <path
          id="Caminho_57116"
          data-name="Caminho 57116"
          d="M36,0A36,36,0,1,1,0,36,36,36,0,0,1,36,0Z"
          transform="translate(-11437 -12653)"
          fill="#fc5166"
        />
        <g id="IconMoneyTaxa" transform="translate(-11417 -12633)">
          <rect
            id="Retângulo_1563"
            data-name="Retângulo 1563"
            width="32"
            height="32"
            fill="none"
            opacity="0"
          />
          <path
            id="Caminho_57055"
            data-name="Caminho 57055"
            d="M757.529,837.926a13.333,13.333,0,1,1,13.333-13.333A13.35,13.35,0,0,1,757.529,837.926Zm0-24A10.667,10.667,0,1,0,768.2,824.593,10.68,10.68,0,0,0,757.529,813.926Zm0,18.667a1.333,1.333,0,0,1-1.333-1.333v-.1a5.972,5.972,0,0,1-3.069-1.616,1.333,1.333,0,0,1,1.875-1.9,3.283,3.283,0,0,0,1.195.754V825.8c-2.325-.433-4-1.98-4-3.879s1.675-3.445,4-3.878v-.122a1.333,1.333,0,0,1,2.667,0v.1a5.96,5.96,0,0,1,3.071,1.615,1.333,1.333,0,1,1-1.875,1.9,3.288,3.288,0,0,0-1.2-.756v2.6c2.326.434,4,1.981,4,3.879s-1.674,3.445-4,3.879v.121A1.332,1.332,0,0,1,757.529,832.593Zm1.333-6.462v2.257c.832-.272,1.333-.765,1.333-1.128S759.695,826.4,758.863,826.131ZM756.2,820.8c-.832.272-1.333.764-1.333,1.129s.5.856,1.333,1.129Z"
            transform="translate(-741.529 -808.593)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export { CirlcleCash };
