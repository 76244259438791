import React from 'react';

import { HashRouter, Routes, Route } from 'react-router-dom';

import GlobalStyle from '../styles';
import Home from '../pages/Home';

import { Product } from '../pages/Register/Product';
import { Address } from '../pages/Register/Address';
import { ReviewData } from '../pages/Register/ReviewData';
import { Success } from '../pages/Register/Success';
import { Terms } from '../pages/Terms';
import { DoctorpayProvider } from '../context';

const Routess: React.FC = () => {
  return (
    <HashRouter>
      <DoctorpayProvider>
        <Routes>
          <Route path="*" element={<Home />} />
          {/* <Route path="/register" element={<Product />} />
          <Route path="/address" element={<Address />} />
          <Route path="/resume" element={<ReviewData />} />
          <Route path="/success" element={<Success />} /> */}
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <GlobalStyle />
      </DoctorpayProvider>
    </HashRouter>
  );
};

export default Routess;
