import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppStore from '../../assets/AppStore.png';
import GooglePlay from '../../assets/GooglePlay.png';

import { Logo, Facebook, Instagram, Twiter } from '../Icons';

import {
  Br,
  Container,
  ContentText,
  ImageStore,
  LinkExterno,
  RowStore,
  Text,
  RowSocial,
  LinkSocial,
  ContentItem,
  ListItem,
  ItemList,
  LogoButton,
} from './styles';

const itemListOne: any[] = [
  { text: 'Quero começar!', type: 'START' },
  { text: 'Simulador', type: 'SIMULATION' },
  { text: 'Como faço', type: 'CONTACT' },
];

const itemListTwo: any[] = [
  { text: 'WhatsApp', type: '', link: 'whatsapp://send?phone=+5515910203040' },
  { text: 'E-mail', type: '', link: 'mailto:contato@doctorpay.com.br' },
];

interface Props {
  bodyRef?: any;
}

const Footer: React.FC<Props> = ({ bodyRef }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleScroll = (type: string) => {
    let itemTop = 0;
    const maxLength = type === 'CONTACT' ? 7 : 1;

    for (let index = 0; index < maxLength; index += 1) {
      itemTop += bodyRef.current.children[index].clientHeight;
    }

    type !== 'START' && type !== 'TERMS'
      ? window.scroll({
          top: itemTop,
          behavior: 'smooth',
        })
      : navigate(type === 'START' ? '/register' : '/terms');
  };

  return (
    <Container>
      <ContentText>
        <LogoButton
          onClick={() =>
            pathname === '/'
              ? window.scroll({ top: 0, behavior: 'smooth' })
              : navigate('/')
          }
        >
          <Logo />
        </LogoButton>

        <Text>
          © 2021 Doctorpay Pagamentos S.A.
          <Br />
          CNPJ 245463243/9098-00
          <Br />
          Rua Teófilo David Muzel, 639, Vila Ophelia, Itapeva/SP, Brasil.
          <Br />
        </Text>
        <Text style={{ marginTop: 22 }}>Desenvolvido por Hexalab®</Text>

        <RowStore>
          <LinkExterno>
            <ImageStore src={GooglePlay} />
          </LinkExterno>
          <LinkExterno>
            <ImageStore src={AppStore} />
          </LinkExterno>
        </RowStore>

        <RowSocial>
          <LinkSocial social="facebook">
            <Facebook />
          </LinkSocial>

          <LinkSocial social="instagram">
            <Instagram />
          </LinkSocial>

          <LinkSocial social="twiter">
            <Twiter />
          </LinkSocial>
        </RowSocial>
      </ContentText>

      <ListItem>
        <ContentItem>
          <ItemList id="item-title">Início</ItemList>

          {itemListOne.map(item => {
            return (
              <ItemList onClick={() => handleScroll(item.type)} key={item.text}>
                {item.text}
              </ItemList>
            );
          })}

          <ItemList onClick={() => handleScroll('TERMS')}>
            Termos de uso
          </ItemList>
        </ContentItem>

        <ContentItem>
          <ItemList id="item-title">Ouvidoria</ItemList>

          {itemListTwo.map(item => {
            return (
              <LinkExterno key={item.text} href={item.link}>
                <ItemList>{item.text}</ItemList>
              </LinkExterno>
            );
          })}

          <ItemList id="span">(10h às 16h em dias úteis)</ItemList>
        </ContentItem>
      </ListItem>
    </Container>
  );
};

export { Footer };
