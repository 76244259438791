import React from 'react';

import { Button } from './styles';

export type colors = 'primary' | 'secondary';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: colors;
  text: string;
  onClick(): any;

  disabled?: boolean;
}

const ButtonSmall: React.FC<Props> = ({
  color,
  disabled,
  text,
  onClick,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      backgroundColor={color}
      {...rest}
    >
      {text}
    </Button>
  );
};

export { ButtonSmall };
