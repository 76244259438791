import styled from 'styled-components';

export const Label = styled.label`
  color: #1a1818;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-top: 24px;
  position: relative;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;
  }

  input {
    color: #fc5166;
    -webkit-text-fill-color: #1a1818;
    margin-top: 7px;
    border: 2px solid #e5ecf8;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-radius: 8px;
    padding: 15px 17px;
    width: 100%;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      padding: 19px 24px;
    }

    ::-webkit-input-placeholder {
      -webkit-text-fill-color: #9aa1b3;
    }

    :disabled {
      background-color: #f5f8fd;
      -webkit-text-fill-color: #979eaf;

      ::-webkit-input-placeholder {
        -webkit-text-fill-color: #979eaf;
      }
    }
  }
`;

export const TextError = styled.p`
  position: absolute;
  right: 0;
  top: calc(100% + 6px);
  color: #fc5166;
`;
