import React from 'react';

import { Resize } from '../../Icons';
import {
  ContainerTextArea,
  ContentAboslute,
  CountyText,
  Label,
  TextareaContent,
  TextError,
} from './styles';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title: string;
  length: number;
  maxLength: number;
  error?: string;
}

const Textarea: React.FC<Props> = ({
  title,
  length,
  maxLength,
  error,
  ...rest
}) => {
  return (
    <Label>
      {title}

      <ContainerTextArea>
        <TextareaContent maxLength={maxLength} {...rest} />

        <ContentAboslute disabled={rest.disabled}>
          <CountyText>
            {length}/{maxLength}
          </CountyText>
          <Resize />
        </ContentAboslute>
      </ContainerTextArea>
      {error && <TextError>{error}</TextError>}
    </Label>
  );
};

export { Textarea };
