import React from 'react';

const Arrow: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.944"
      height="38.963"
      viewBox="0 0 26.944 38.963"
    >
      <g
        id="Grupo_50508"
        data-name="Grupo 50508"
        transform="matrix(-0.951, -0.309, 0.309, -0.951, 19.078, 41.763)"
      >
        <path
          id="Caminho_57106"
          data-name="Caminho 57106"
          d="M3.725,0a1,1,0,0,0-.943.668A42.605,42.605,0,0,0,.016,16,21.609,21.609,0,0,0,2.779,26.234a19.537,19.537,0,0,0,8.086,7.51,19.044,19.044,0,0,0,4.758,1.724,1.015,1.015,0,0,0,.18.016A1,1,0,0,0,15.98,33.5h0A17.567,17.567,0,0,1,11.7,31.926a18.553,18.553,0,0,1-3.752-2.533A16.618,16.618,0,0,1,4.494,25.2a19.666,19.666,0,0,1-2.48-9.289A40.66,40.66,0,0,1,4.668,1.333,1,1,0,0,0,4.057.058.989.989,0,0,0,3.725,0Z"
          transform="translate(3.82 1.729)"
          fill="#fc5166"
        />
        <path
          id="Caminho_57107"
          data-name="Caminho 57107"
          d="M9,0H1A1,1,0,0,0,0,1V9A1,1,0,0,0,2,9V2H9A1,1,0,0,0,9,0Z"
          transform="translate(9.062 0) rotate(65)"
          fill="#fc5166"
        />
      </g>
    </svg>
  );
};

export { Arrow };
