import styled from 'styled-components';

export const Label = styled.label`
  color: #1a1818;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const SelectContent = styled.button`
  margin-top: 7px;
  cursor: pointer;
  border: 2px solid #e5ecf8;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  line-height: 21px;
  color: #1a1818;
  display: flex;
  width: 100%;
  font-weight: 400;
  padding: 15px 16px;
  z-index: 2;
  position: relative;

  @media (min-width: 768px) {
    padding: 20px 24px;
    border-radius: 30px;
    font-size: 16px;
    line-height: 24px;
  }

  :disabled {
    background-color: #f5f8fd;
  }

  svg {
    position: absolute;
    right: 16px;

    @media (min-width: 768px) {
      right: 24px;
    }
  }
`;

interface ContentOptionProps {
  active: boolean;
}

export const ContentOption = styled.ul<ContentOptionProps>`
  display: ${props => (props.active ? 'block' : 'none')};
  position: absolute;
  flex-direction: column;
  background-color: #fff;
  max-height: 346px;
  place-self: center;
  justify-content: center;
  border-radius: 25px;
  box-shadow: 0px 3px 6px #e5ecf8;
  padding: 79px 0px 24px 0px;
  margin-top: 10px;
  width: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 92px 0px 24px 0px;
    border-radius: 30px;
  }
`;

export const OptionSytled = styled.li<ContentOptionProps>`
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  color: #1a1818;
  display: ${props => (props.active ? 'block' : 'none')};
  padding: 12px 22px;
  position: relative;

  ${props => props.active && 'background-color: #e5ecf8;'}

  @media (min-width: 768px) {
    padding: 15px 24px;
  }

  :hover {
    background-color: #f5f8fd;
  }

  :focus {
    background-color: #e5ecf8;
  }
`;
