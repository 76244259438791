import React from 'react';

const Resize: React.FC = () => {
  return (
    <svg
      id="IconResizeTextArea"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Retângulo_1563"
        data-name="Retângulo 1563"
        width="24"
        height="24"
        fill="none"
        opacity="0"
      />
      <path
        id="Caminho_57032"
        data-name="Caminho 57032"
        d="M7205,20213.5a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,7205,20213.5Zm-4,0a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,7201,20213.5Zm-4,0a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,7197,20213.5Zm8-4a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,7205,20209.5Zm-4,0a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,7201,20209.5Zm4-4a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,7205,20205.5Z"
        transform="translate(-7190.499 -20197.504)"
        fill="#1a1818"
      />
    </svg>
  );
};

export { Resize };
