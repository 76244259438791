import React from 'react';
import {
  Getz,
  HexaLab,
  Microbanco,
  Whitelabel,
} from '../../../components/Icons';

import { Container, ContainerIcon, ContentIcon, Row, Title } from './styles';

const Partner: React.FC = () => {
  return (
    <Container>
      <Title>Nossos parceiros</Title>

      <ContainerIcon>
        <Row>
          <ContentIcon id="microbanco">
            <Microbanco />
          </ContentIcon>
          <ContentIcon id="whitelabel">
            <Whitelabel />
          </ContentIcon>
        </Row>
        <Row>
          <ContentIcon id="getz">
            <Getz />
          </ContentIcon>

          <ContentIcon id="hexa-lab">
            <HexaLab />
          </ContentIcon>
        </Row>
      </ContainerIcon>
    </Container>
  );
};

export { Partner };
