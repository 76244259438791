import React from 'react';

const useDebounce = (
  value: string | number,
  delay: number,
): string | number => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useMemo(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
