import React from 'react';

import { Chevron } from '../../../components/Icons';
import { ButtonIcon } from '../../../components/Button';
import { CardRate } from '../../../components/CardRate';
import { ItemList } from './ItemList';
import {
  Container,
  Content,
  ContentRates,
  ContentRating,
  SubTitle,
  Text,
  TextSpan,
  Title,
} from './styles';

const Rating: React.FC = () => {
  return (
    <Container>
      <Content>
        <Title>É muito simples!</Title>
        <Text>
          E se tiver qualquer dúvida é só <TextSpan>entrar em contato</TextSpan>
        </Text>

        <ItemList />
      </Content>
      <ContentRating>
        <SubTitle>
          Quem já <TextSpan>aprovou</TextSpan> o Doctorpay
        </SubTitle>
        <ContentRates>
          <CardRate />
          <CardRate />
          <CardRate />
        </ContentRates>
      </ContentRating>
    </Container>
  );
};

export { Rating };
