import React from 'react';

import { Navbar } from '../../components/Navbar';
import { Carrousel } from './Carrousel';
import { Simulation } from './Simulation';
import { Description } from './Description';
import { Partner } from './Partner';
import { DescriptionTwo } from './DescriptionTwo';
import { Rating } from './Rating';
import { Doubt } from './Doubt';
import { Contact } from './Contact';
import { Footer } from '../../components/Footer';
import { Body, Main } from './styles';

const Home: React.FC = () => {
  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });

  return (
    <>
      <Navbar bodyRef={mapRef} />
      <Main>
        <Body ref={mapRef}>
          <Carrousel bodyRef={mapRef} />
          <Simulation />
          <Description />
          <Partner />
          <DescriptionTwo />
          <Rating />
          <Doubt />
          <Contact />
        </Body>
        <Footer bodyRef={mapRef} />
      </Main>
    </>
  );
};

export default Home;
