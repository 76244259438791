import React from 'react';
import { useNavigate } from 'react-router-dom';

import services from '../../../services';
import useDebounce from '../../../hooks/useDebounce';

import { ButtonSmall } from '../../../components/Button';
import { Select, Radio, InputCurrency } from '../../../components/Form';
import { ItemData } from '../../../components/Form/Select';
import { ResultData } from '../../../services/doctorpay';
import {
  Br,
  Container,
  Title,
  TitleSpan,
  Formulary,
  Content,
  ResultDiv,
  ResultContent,
  TitleResult,
  SubPrice,
  TextPrice,
  TextPriceSpan,
  NewPrice,
  RowResult,
  CardContent,
  TextCard,
  IconCards,
  RadioContent,
} from './styles';
import {
  American,
  Arrow,
  Diners,
  Elo,
  Hipercard,
  Mastercard,
  Visa,
} from '../../../components/Icons';
import handleCurrency from '../../../utils/handleCurrency';

const paymentType: ItemData[] = [{ text: 'Pix', value: 'Pix' }];

const typeCard: ItemData[] = [
  { text: 'Maquininha', value: 'MACHINE' },
  // { text: 'Link Pagamento', value: 'LINK' },
];

const Simulation: React.FC = () => {
  const [amount, setAmount] = React.useState<string>('1200,00');
  const value = useDebounce(amount?.toString(), 500);

  const [typeItem, setTypeItem] = React.useState<string>(typeCard[0].value);
  const [resultData, setResultData] = React.useState<ResultData>({
    installmentValue: 0,
    numberInstallments: 0,
    paymentForm: '',
    paymentType: '',
    totalValue: 0,
    value: 0,
  });

  const navigation = useNavigate();

  const simulation = async () => {
    const { res } = await services.doctorpay.simulation({
      numberInstallments: 12,
      paymentForm: '1',
      paymentType: 'pix',
      value: parseFloat(amount.replace('.', '').replace(',', '.')),
    });

    if (res) {
      setResultData(res.resultData);
    }
  };

  React.useEffect(() => {
    simulation();
  }, [value]);

  return (
    <Container>
      <Title>
        Faça uma simulação
        <Br />e se<TitleSpan> surpreenda!</TitleSpan>
      </Title>

      <Content>
        <Formulary>
          <RadioContent>
            {typeCard.map(type => {
              return (
                <Radio
                  key={type.value}
                  value={type.value}
                  actualValue={typeItem}
                  setValue={setTypeItem}
                  text={type.text.toString()}
                />
              );
            })}
          </RadioContent>

          <InputCurrency
            title="Valor total do serviço"
            value={amount}
            onValueChange={(input: any) => setAmount(input)}
          />

          <Select title="Forma de pagamento" itens={paymentType} disabled />
        </Formulary>
        <ResultContent>
          <RowResult>
            <ResultDiv>
              <TitleResult>
                Seu paciente paga
                <Arrow />
              </TitleResult>
              <SubPrice>
                Total de {handleCurrency(resultData.totalValue)}
              </SubPrice>
              <TextPrice>
                <TextPriceSpan>{resultData.numberInstallments}x </TextPriceSpan>
                {handleCurrency(resultData.installmentValue)}
              </TextPrice>
            </ResultDiv>

            <ResultDiv>
              <TitleResult>
                Você recebe em 1 dia útil
                <Arrow />
              </TitleResult>
              <NewPrice>{handleCurrency(resultData.value)}</NewPrice>
              <ButtonSmall
                text="Começar!"
                color="primary"
                onClick={() => navigation('/register')}
              />
            </ResultDiv>
          </RowResult>

          <CardContent>
            <TextCard>ACEITAMOS</TextCard>
            <IconCards>
              <Visa />
              <Mastercard />
              <Elo />
              <American />
              <Diners />
              <Hipercard />
            </IconCards>
          </CardContent>
        </ResultContent>
      </Content>
    </Container>
  );
};

export { Simulation };
