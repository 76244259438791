import React from 'react';

import { InputContent, Label, TextError } from './styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  error?: string;
}

const Input: React.FC<Props> = ({ title, error, ...rest }) => {
  return (
    <Label>
      {title}

      <InputContent {...rest} />
      {error && <TextError>{error}</TextError>}
    </Label>
  );
};

export { Input };
