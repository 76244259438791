import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    font-family: 'Commissioner', sans-serif;

    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  #microbanco {
    width: 63.5px;
    height: 33.3px;

    :hover path {
      fill: #16355a;
    }
  }

  #getz {
    width: 57.29px;
    height: 33.3px;

    :hover path {
      fill: #ff004E;
    }
  }

  #whitelabel {
    width: 70.52px;
    height: 20px;

    :hover path {
      fill: #005DF2;
    }
  }

  #hexa-lab {
    width: 79px;
    height: 44px;

    :hover {
      path {
        fill: #fc5166;

        :nth-last-child(4){
          fill: #000;
        }
      }
    }
  }

  @media(min-width: 768px){
    #microbanco {
      width: 101.68px;
      height: 53.33px;
    }

    #getz {
      width: 91.67px;
      height: 53.3px;
    }

    #whitelabel {
      width: 112.83px;
      height: 32px;
    }

    #hexa-lab {
      width: 169px;
      height: 44px;
    }
  }

`;
