import React from 'react';

const Twiter: React.FC = () => {
  return (
    <svg
      id="IconTwitter"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Retângulo_5783"
        data-name="Retângulo 5783"
        width="32"
        height="32"
        fill="none"
        opacity="0"
      />
      <path
        id="Twitter-Logo.wine"
        d="M8.482,22.306H8.454A16.656,16.656,0,0,1-.4,19.751a1.265,1.265,0,0,1-.523-1.505,1.3,1.3,0,0,1,1.377-.838,9.752,9.752,0,0,0,1.12.064,9.585,9.585,0,0,0,3.348-.595,6.532,6.532,0,0,1-2.943-3.62A1.255,1.255,0,0,1,2.05,12.3,6.463,6.463,0,0,1,.045,7.642c0-.039,0-.075,0-.112A1.272,1.272,0,0,1,.649,6.5,6.423,6.423,0,0,1,.987.594,1.314,1.314,0,0,1,3.119.429a13.975,13.975,0,0,0,8.559,4.85A6.591,6.591,0,0,1,18.312-1,6.748,6.748,0,0,1,22.591.534a9.471,9.471,0,0,0,2.342-.972,1.322,1.322,0,0,1,1.224-.054,1.279,1.279,0,0,1,.727.968,6.362,6.362,0,0,1-.449,4,4.779,4.779,0,0,1-1.466,1.612q0,.016,0,.032a16.539,16.539,0,0,1-4.236,10.893,15.639,15.639,0,0,1-5.189,3.816A16.933,16.933,0,0,1,8.482,22.306Zm-3.066-2.89a14.113,14.113,0,0,0,3.067.336h.025A13.619,13.619,0,0,0,22.367,6.123c0-.213,0-.422-.014-.619a1.268,1.268,0,0,1,.537-1.092l.224-.156a2.332,2.332,0,0,0,1.235-1.6,12.174,12.174,0,0,1-1.889.526,1.316,1.316,0,0,1-1.2-.379,4.055,4.055,0,0,0-6.984,2.709,3.9,3.9,0,0,0,.1.9,1.259,1.259,0,0,1-.268,1.109,1.312,1.312,0,0,1-1.064.457A16.661,16.661,0,0,1,2.692,3.7q0,.083,0,.165a3.939,3.939,0,0,0,1.8,3.3A1.265,1.265,0,0,1,5,8.619a1.3,1.3,0,0,1-1.279.887Q3.409,9.5,3.1,9.458a4.025,4.025,0,0,0,2.786,2.068,1.272,1.272,0,0,1,.086,2.484q-.235.063-.474.109a4.076,4.076,0,0,0,2.732,1.112,1.3,1.3,0,0,1,1.209.871A1.263,1.263,0,0,1,9,17.511,12.062,12.062,0,0,1,5.415,19.416Z"
        transform="translate(3 5)"
        fill="#727882"
      />
    </svg>
  );
};

export { Twiter };
