import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: 36px;

  @media (min-width: 768px) {
    margin-top: 96px;
    padding: 80px 5% 93px 25%;
  }

  @media (min-width: 1366px) {
    margin-top: 95px;
  }
`;

export const Title = styled.h1`
  color: #1a1818;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 51px;
  }
`;

export const SpanTitle = styled.span`
  color: #fc5166;
`;

export const SubTitle = styled.h3`
  color: #1a1818;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }
`;

export const Describe = styled.p`
  color: #1a1818;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 54px;
  }
`;

export const ContentCircle = styled.section`
  display: flex;
  padding: 12px;
  background-color: #00c1701a;
  border-radius: 24px;
  max-width: 369px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TextCircle = styled.p`
  color: #1a1818;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    min-width: 369px;
  }
`;

export const ListButton = styled.section`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 5%;
  top: calc(64px + 36px);

  @media (min-width: 768px) {
    top: calc(96px + 80px);
  }

  @media (min-width: 1366px) {
    top: calc(95px + 80px);
  }
`;

interface ButtonListProps {
  active: boolean;
}
export const ButtonList = styled.button<ButtonListProps>`
  background-color: ${props => (props.active ? '#fc51661a' : 'transparent')};
  border: 0;
  border-radius: 16px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
  color: ${props => (props.active ? '#fc5166' : '#727882')};

  @media (min-width: 768px) {
    min-width: 132px;
    font-size: 20px;
    line-height: 30px;
    padding: 14px 20px 15px 16px;
  }

  :disabled {
    cursor: default;
  }
`;

export const Scope = styled.div``;
