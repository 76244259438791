import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  padding-bottom: 21px;

  @media (min-width: 768px) {
    padding-bottom: 0px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;

  @media (min-width: 768px) {
    margin-top: 96px;
    margin-bottom: 0px;
  }

  @media (min-width: 1366px) {
    margin-top: 95px;
  }
`;
