import React from 'react';

const CheckCircle: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <path
        id="Caminho_57116"
        data-name="Caminho 57116"
        d="M36,0A36,36,0,1,1,0,36,36,36,0,0,1,36,0Z"
        fill="#00c170"
      />
      <g id="IconCheck" transform="translate(20 20)">
        <rect
          id="Retângulo_1563"
          data-name="Retângulo 1563"
          width="32"
          height="32"
          fill="none"
          opacity="0"
        />
        <path
          id="Caminho_57058"
          data-name="Caminho 57058"
          d="M10.667,22a1.329,1.329,0,0,1-.943-.391L4.391,16.276a1.333,1.333,0,1,1,1.886-1.886l4.391,4.391L23.057,6.391a1.333,1.333,0,1,1,1.886,1.886L11.609,21.609A1.329,1.329,0,0,1,10.667,22Z"
          transform="translate(1.333 2)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export { CheckCircle };
