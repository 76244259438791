import React from 'react';

const Chevron: React.FC = () => {
  return (
    <svg
      id="IconArrowChevron"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Retângulo_1563"
        data-name="Retângulo 1563"
        width="24"
        height="24"
        fill="none"
        opacity="0"
      />
      <path
        id="Caminho_255"
        data-name="Caminho 255"
        d="M889,847h-6a1,1,0,0,1-1-1v-6a1,1,0,0,1,2,0v5h5a1,1,0,0,1,0,2Z"
        transform="translate(-1210.845 42.148) rotate(-45)"
        fill="#fc5166"
      />
    </svg>
  );
};

export { Chevron };
