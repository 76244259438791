import React from 'react';

import { Form } from './Form';
import { Chevron, WhatsApp, Mail, Location } from '../../../components/Icons';
import {
  Container,
  ContainerLinks,
  Content,
  ContentLink,
  Hr,
  LinkItem,
  LinkText,
  Scope,
  SubText,
  Title,
} from './styles';

export const contacts: any[] = [
  {
    text: '(15) 91020-3040',
    icon: <WhatsApp />,
    link: 'whatsapp://send?phone=+5515910203040',
  },
  {
    text: 'contato@doctorpay.com.br',
    icon: <Mail />,
    link: 'mailto:contato@doctorpay.com.br',
  },
  {
    text: '18400-000',
    icon: <Location />,
    link: 'https://www.google.com.br/maps/search/18400000',
  },
];

const Contact: React.FC = () => {
  return (
    <Container>
      <Content>
        <Title>Contato</Title>
        <SubText>Escolha uma das opções para falar com a gente.</SubText>

        <ContainerLinks>
          {contacts.map((contact, index) => {
            return (
              <Scope key={contact.text}>
                {index !== 0 && <Hr />}
                <LinkItem target="_blank" href={contact.link}>
                  <ContentLink>
                    {contact.icon}
                    <LinkText>{contact.text}</LinkText>
                  </ContentLink>
                  <Chevron />
                </LinkItem>
              </Scope>
            );
          })}
        </ContainerLinks>
      </Content>

      <Form />
    </Container>
  );
};

export { Contact };
