import styled from 'styled-components';

export const ContainerRadio = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
`;

interface ContentRadioProps {
  active: boolean;
}
export const ContentRadio = styled.div<ContentRadioProps>`
  display: flex;
  padding: 4px;
  border-radius: 100%;
  transition: 0.5s all;
  border: 2px solid ${props => (props.active ? '#fc5166' : '#979eaf')};
  margin-right: 8px;
`;

export const RadioCheck = styled.div<ContentRadioProps>`
  display: flex;
  background-color: ${props => (props.active ? '#fc5166' : 'transparent')};
  width: 12px;
  height: 12px;
  border-radius: 32px;
  transition: 0.5s all;
`;

export const TextRadio = styled.p`
  color: #1a1818;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
