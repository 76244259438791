import React from 'react';

import { ItemMenu } from './ItemMenu';
import { Container, ListItem, Text, Title } from './styles';

const Doubt: React.FC = () => {
  return (
    <Container>
      <Title>Dúvidas frequentes</Title>

      <ListItem>
        <ItemMenu />
        <ItemMenu />
        <Text>Não encontrou sua resposta?</Text>
      </ListItem>
    </Container>
  );
};

export { Doubt };
