import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 288px;
  border-radius: 16px;
  background-color: #f2f6ff;
  padding: 24px 24px 43px 24px;
  margin-right: 12px;

  @media (min-width: 768px) {
    min-width: 352px;
    padding: 24px 24px 22px 24px;
    margin-right: 16px;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImageAvatar = styled.div<{ src: string }>`
  display: flex;
  min-width: 56px;
  min-height: 56px;
  border-radius: 56px;
  background-size: cover;
  background-position: center;
  margin-right: 12px;

  background-image: url(${props => props.src});

  @media (min-width: 768px) {
    min-width: 80px;
    min-height: 80px;
    border-radius: 80px;
    margin-right: 24px;
  }
`;

export const Title = styled.p`
  color: #1a1818;
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 21px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 3px;
  }
`;

export const SubTitle = styled.p`
  color: #727882;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 3px;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0px;
  }
`;

export const ContentStar = styled.div`
  display: flex;
`;

export const Description = styled.p`
  color: #727882;
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }
`;
