import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f6ff;
  padding: 80px 36px;
`;

export const Title = styled.h1`
  color: #1a1818;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 37px;
`;

export const ListItem = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;

  @media (min-width: 768px) {
    max-width: 656px;
    margin: 0px auto;
  }
`;

export const Text = styled.h4`
  font-weight: 500;
  color: #eb3340;
  margin-top: 24px;
  margin-left: 24px;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 27px;
    margin-top: 22px;
    margin-left: 32px;
  }
`;
