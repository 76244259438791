const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: 'R$',
};

export default (value: string | number | undefined) => {
  let val: any = value;

  if (typeof val !== 'number') {
    val = 0.0;
  }
  const options = { ...defaultOptions };

  val = val.toFixed(options.significantDigits);

  const [currency, decimal] = val.split('.');

  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator,
  )}${options.decimalSeparator}${decimal}`;
};
