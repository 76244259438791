import React from 'react';

const Mail: React.FC = () => {
  return (
    <svg
      id="IconEmail"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Retângulo_1563"
        data-name="Retângulo 1563"
        width="32"
        height="32"
        fill="none"
        opacity="0"
      />
      <g
        id="Grupo_19370"
        data-name="Grupo 19370"
        transform="translate(2.667 6.667)"
      >
        <path
          id="Caminho_57112"
          data-name="Caminho 57112"
          d="M2,7.14,15.331,13.8,28.66,7.14A3.333,3.333,0,0,0,25.331,4h-20A3.333,3.333,0,0,0,2,7.141Z"
          transform="translate(-1.998 -4)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57113"
          data-name="Caminho 57113"
          d="M28.667,8.118,15.333,14.785,2,8.118v9.8a3.333,3.333,0,0,0,3.333,3.333h20a3.333,3.333,0,0,0,3.333-3.333Z"
          transform="translate(-2 -1.255)"
          fill="#1a1818"
        />
      </g>
    </svg>
  );
};

export { Mail };
