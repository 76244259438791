import React from 'react';

import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import {
  Main,
  Body,
  ButtonList,
  SubTitle,
  SpanTitle,
  Title,
  ContentCircle,
  Describe,
  ListButton,
  TextCircle,
  Scope,
} from './styles';

const list: any[] = [
  {
    title: 'Introdução',
    type: 'INTRODUCTION',
    describe: `Este documento Declaração de Política de Privacidade e Condições de Uso ou apenas “Declaração”, é aplicável a todos que utilizarem ou acessarem nossa plataforma Getz Food, aqui denominados como TITULAR. A HEXALAB SOLUCOES EM SOFTWARE LTDA, com sede na cidade de Itapeva/SP, estabelecida na Rua Teófilo David Muzel, nº 639, sala 05, Vila Ophélia, CEP 18400-816, inscrito no CNPJ sob nº 37.267.428/0001-05, doravante designada como HEXALAB, exerce o papel de controlador dos Tratamentos dos Dados.
              O presente documento tem como objetivo informar, de forma clara, sobre como irá ocorrer o Armazenamento, Uso e Tratamento dos dados do TITULAR na plataforma Getz Food. Destacamos que os dados pertencem ao TITULAR e a HEXALAB respeita e protege ao máximo a privacidade de todos os envolvidos.
              A HEXALAB divulga sua Declaração  para proteger a privacidade do Titular, garantindo que o Tratamento dos Dados Pessoais servirá apenas para possibilitar a prestação dos Serviços ou para outras finalidades previstas nesta Política. Para utilizar todos os Serviços disponíveis na plataforma Getz Food de maneira completa o Titular deve estar de acordo com as condições previstas na Política de Privacidade e declarar-se ciente de como ocorrerá o Tratamento de seus Dados.
              A Declaração de Política de Privacidade e Condições de Uso para o aplicativo Getz Food baseia-se na Lei nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais, que aborda o Tratamento de Dados, nos meios físicos e digitais, tanto o objetivo desta Política quanto o da Lei são apresentar meios para garantir a segurança e privacidade dos Dados dos Titulares.
              A HEXALAB poderá alterar as condições e definições desta Política periodicamente, sendo que a versão atualizada fica disponível na plataforma para consulta pública.  O Titular conseguirá tirar suas dúvidas e solicitar informações a qualquer momento através da plataforma Getz Food. `,
  },
  {
    title: 'Dados',
    type: 'DATA',
    describe: `Este documento Declaração de Política de Privacidade e Condições de Uso ou apenas “Declaração”, é aplicável a todos que utilizarem ou acessarem nossa plataforma Getz Food, aqui denominados como TITULAR. A HEXALAB SOLUCOES EM SOFTWARE LTDA, com sede na cidade de Itapeva/SP, estabelecida na Rua Teófilo David Muzel, nº 639, sala 05, Vila Ophélia, CEP 18400-816, inscrito no CNPJ sob nº 37.267.428/0001-05, doravante designada como HEXALAB, exerce o papel de controlador dos Tratamentos dos Dados.
              O presente documento tem como objetivo informar, de forma clara, sobre como irá ocorrer o Armazenamento, Uso e Tratamento dos dados do TITULAR na plataforma Getz Food. Destacamos que os dados pertencem ao TITULAR e a HEXALAB respeita e protege ao máximo a privacidade de todos os envolvidos.
              A HEXALAB divulga sua Declaração  para proteger a privacidade do Titular, garantindo que o Tratamento dos Dados Pessoais servirá apenas para possibilitar a prestação dos Serviços ou para outras finalidades previstas nesta Política. Para utilizar todos os Serviços disponíveis na plataforma Getz Food de maneira completa o Titular deve estar de acordo com as condições previstas na Política de Privacidade e declarar-se ciente de como ocorrerá o Tratamento de seus Dados.
              A Declaração de Política de Privacidade e Condições de Uso para o aplicativo Getz Food baseia-se na Lei nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais, que aborda o Tratamento de Dados, nos meios físicos e digitais, tanto o objetivo desta Política quanto o da Lei são apresentar meios para garantir a segurança e privacidade dos Dados dos Titulares.
              A HEXALAB poderá alterar as condições e definições desta Política periodicamente, sendo que a versão atualizada fica disponível na plataforma para consulta pública.  O Titular conseguirá tirar suas dúvidas e solicitar informações a qualquer momento através da plataforma Getz Food. `,
  },
  {
    title: 'Teste',
    type: 'TESTE',
    describe: `Este documento Declaração de Política de Privacidade e Condições de Uso ou apenas “Declaração”, é aplicável a todos que utilizarem ou acessarem nossa plataforma Getz Food, aqui denominados como TITULAR. A HEXALAB SOLUCOES EM SOFTWARE LTDA, com sede na cidade de Itapeva/SP, estabelecida na Rua Teófilo David Muzel, nº 639, sala 05, Vila Ophélia, CEP 18400-816, inscrito no CNPJ sob nº 37.267.428/0001-05, doravante designada como HEXALAB, exerce o papel de controlador dos Tratamentos dos Dados.
              O presente documento tem como objetivo informar, de forma clara, sobre como irá ocorrer o Armazenamento, Uso e Tratamento dos dados do TITULAR na plataforma Getz Food. Destacamos que os dados pertencem ao TITULAR e a HEXALAB respeita e protege ao máximo a privacidade de todos os envolvidos.
              A HEXALAB divulga sua Declaração  para proteger a privacidade do Titular, garantindo que o Tratamento dos Dados Pessoais servirá apenas para possibilitar a prestação dos Serviços ou para outras finalidades previstas nesta Política. Para utilizar todos os Serviços disponíveis na plataforma Getz Food de maneira completa o Titular deve estar de acordo com as condições previstas na Política de Privacidade e declarar-se ciente de como ocorrerá o Tratamento de seus Dados.
              A Declaração de Política de Privacidade e Condições de Uso para o aplicativo Getz Food baseia-se na Lei nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais, que aborda o Tratamento de Dados, nos meios físicos e digitais, tanto o objetivo desta Política quanto o da Lei são apresentar meios para garantir a segurança e privacidade dos Dados dos Titulares.
              A HEXALAB poderá alterar as condições e definições desta Política periodicamente, sendo que a versão atualizada fica disponível na plataforma para consulta pública.  O Titular conseguirá tirar suas dúvidas e solicitar informações a qualquer momento através da plataforma Getz Food. `,
  },
];

const sectionList: any[] = [
  { type: 'INTRODUCTION', text: 'Introdução' },
  { type: 'DATA', text: 'Dados' },
  { type: 'TESTE', text: 'Teste' },
];

const Terms: React.FC = () => {
  const [active, setActive] = React.useState<string>('INTRODUCTION');

  const refList = React.useRef<HTMLDivElement>(null);

  const handleScroll = (itemIndex: number) => {
    let top = 0;

    for (let index = 1; index < itemIndex + 1; index += 1) {
      const itemHeight: any = refList.current?.children[index].clientHeight;
      top += itemHeight;
    }

    window.scroll({ top: top + 100, behavior: 'smooth' });
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });

  return (
    <>
      <Navbar isHide />
      <Main>
        <Body ref={refList}>
          <Title>
            Termos de uso <SpanTitle>Doctorpay</SpanTitle>
          </Title>

          {list.map(item => (
            <Scope key={item.title}>
              <SubTitle>{item.title}</SubTitle>
              <Describe>{item.describe}</Describe>
            </Scope>
          ))}

          <ContentCircle>
            <TextCircle>Aqui!</TextCircle>
          </ContentCircle>
        </Body>
        <Footer />

        <ListButton>
          {sectionList.map((section, index) => (
            <ButtonList
              key={section.type}
              disabled={active === section.type}
              onClick={() => {
                setActive(section.type);
                handleScroll(index);
              }}
              active={active === section.type}
            >
              {section.text}
            </ButtonList>
          ))}
        </ListButton>
      </Main>
    </>
  );
};

export { Terms };
