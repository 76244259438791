import React from 'react';
import { Chevron } from '../../Icons';

import {
  Label,
  SelectContent,
  OptionSytled,
  ContentOption,
  ContainerSelect,
} from './styles';

export interface ItemData {
  value: string;
  text: string | number;
}

interface Props {
  title: string;
  itens: ItemData[];

  disabled?: boolean;
  zIndex?: number;
}

const Select: React.FC<Props> = ({ title, itens, disabled, zIndex }) => {
  const [active, setActive] = React.useState<boolean>(false);
  const [check, setCheck] = React.useState<string>(itens[0].value);

  return (
    <Label style={{ zIndex }}>
      {title}
      <ContainerSelect>
        <SelectContent onClick={() => setActive(!active)} disabled={disabled}>
          {check}
          <Chevron />
        </SelectContent>
        <ContentOption active={active}>
          {itens.map(item => {
            return (
              <OptionSytled
                active={active}
                key={item.value}
                onClick={() => setCheck(item.value)}
              >
                {item.text}
              </OptionSytled>
            );
          })}
        </ContentOption>
      </ContainerSelect>
    </Label>
  );
};

export { Select };
