import styled from 'styled-components';
import { colors } from '.';

interface Props {
  backgroundColor: colors;
}

export const Button = styled.button<Props>`
  cursor: pointer;
  position: relative;
  min-width: 302px;
  max-height: 60px;
  width: auto;
  display: flex;
  padding: 20px 0px;
  border: 2px solid #fc5166;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: all 0.5s;
  transform-origin: center;
  font-size: 16px;
  line-height: 21px;
  color: ${props => (props.backgroundColor === 'primary' ? '#fff' : '#fc5166')};
  background-color: ${props =>
    props.backgroundColor === 'primary' ? '#fc5166' : '#fff'};

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 27px;
    width: 262px;
    padding: 24px 48px;
    max-height: 75px;
    border-radius: 24px;
  }

  :hover,
  :focus {
    background-color: ${props =>
      props.backgroundColor === 'primary' ? '#E1485B' : '#Feecee'};
  }

  :disabled {
    background-color: ${props =>
      props.backgroundColor === 'primary' ? '#FDB8C1' : '#fff'};
    border: 2px solid
      ${props => (props.backgroundColor === 'primary' ? '#FDB8C1' : '#E5ECF8')};
    ${props => props.backgroundColor === 'secondary' && 'color: #E5ECF8'};
  }
`;

interface IconProps {
  backgroundColor: colors;
  disabled?: boolean;
}

export const Icon = styled.div<IconProps>`
  position: absolute;
  left: 24px;
  top: 18px;

  @media (min-width: 768px) {
    left: 48px;
    top: 24px;
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${props =>
        props.disabled && props.backgroundColor === 'secondary'
          ? '#979EAF'
          : !props.disabled && props.backgroundColor === 'secondary'
          ? '#fc5166'
          : '#fff'};
    }
  }
`;
