import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      id="LogoDoctorpay"
      xmlns="http://www.w3.org/2000/svg"
      width="164.029"
      height="31.999"
      viewBox="0 0 164.029 31.999"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_5733"
            data-name="Retângulo 5733"
            width="6.588"
            height="18.824"
            fill="#fc5166"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Retângulo_5735"
            data-name="Retângulo 5735"
            width="18.823"
            height="6.588"
            fill="#fc5166"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_19309"
        data-name="Grupo 19309"
        transform="translate(6.117 6.589)"
      >
        <g id="Grupo_19308" data-name="Grupo 19308" clipPath="url(#clip-path)">
          <rect
            id="Retângulo_5732"
            data-name="Retângulo 5732"
            width="214.282"
            height="226.517"
            transform="translate(-103.847 -103.847)"
            fill="#fc5166"
          />
        </g>
      </g>
      <g
        id="Grupo_19311"
        data-name="Grupo 19311"
        transform="translate(0 12.706)"
      >
        <g
          id="Grupo_19310"
          data-name="Grupo 19310"
          clipPath="url(#clip-path-2)"
        >
          <rect
            id="Retângulo_5734"
            data-name="Retângulo 5734"
            width="226.517"
            height="214.282"
            transform="translate(-103.847 -103.847)"
            fill="#fc5166"
          />
        </g>
      </g>
      <g
        id="Grupo_19316"
        data-name="Grupo 19316"
        transform="translate(34.21 4.001)"
      >
        <path
          id="Caminho_57061"
          data-name="Caminho 57061"
          d="M33.919,76.141A2.566,2.566,0,0,0,35.1,75.92a.762.762,0,0,0,.42-.725V69.964a3.451,3.451,0,0,0-.71-.288,2.664,2.664,0,0,0-.737-.106,4.818,4.818,0,0,0-1.681.263,1.918,1.918,0,0,0-1.079,1.025,5.3,5.3,0,0,0-.369,2.236,4.041,4.041,0,0,0,.317,1.72,2.071,2.071,0,0,0,.971,1,3.762,3.762,0,0,0,1.683.327m-.209,3.944a12.816,12.816,0,0,1-3.221-.366,5.84,5.84,0,0,1-2.366-1.2,5.184,5.184,0,0,1-1.46-2.209,10.114,10.114,0,0,1-.5-3.4,7.781,7.781,0,0,1,1.629-5.219,6.081,6.081,0,0,1,4.89-1.907c.228,0,.553.014.973.039a4.8,4.8,0,0,1,1.368.317,3.148,3.148,0,0,1,1.339,1.037l-.841,1.474V60.869h4.733V75.723a3.374,3.374,0,0,1-.526,1.865A4.393,4.393,0,0,1,38.3,78.955a7.321,7.321,0,0,1-2.089.843A10.5,10.5,0,0,1,33.71,80.085Z"
          transform="translate(-26.164 -60.869)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57062"
          data-name="Caminho 57062"
          d="M41.159,73.812a2.217,2.217,0,0,0,1.248-.354,2.3,2.3,0,0,0,.841-1.118,5.387,5.387,0,0,0,.3-1.971,6,6,0,0,0-.3-2.182,1.811,1.811,0,0,0-.841-1,3.13,3.13,0,0,0-2.511,0,1.673,1.673,0,0,0-.787,1,6.839,6.839,0,0,0-.263,2.182,4.539,4.539,0,0,0,.59,2.654,2.022,2.022,0,0,0,1.722.789m0,3.733a7.115,7.115,0,0,1-5.271-1.787,7.363,7.363,0,0,1-1.774-5.389q0-3.919,1.774-5.521a7.633,7.633,0,0,1,5.271-1.6,9.654,9.654,0,0,1,3.878.7A4.889,4.889,0,0,1,47.455,66.2a8.923,8.923,0,0,1,.828,4.167,7.2,7.2,0,0,1-1.84,5.389A7.27,7.27,0,0,1,41.159,77.544Z"
          transform="translate(-17.657 -58.327)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57063"
          data-name="Caminho 57063"
          d="M49.055,77.49A8.369,8.369,0,0,1,46.15,77a7.092,7.092,0,0,1-2.327-1.379,6.237,6.237,0,0,1-1.551-2.143,6.847,6.847,0,0,1-.553-2.8,8.95,8.95,0,0,1,.5-3.074,6.414,6.414,0,0,1,1.447-2.327,6.349,6.349,0,0,1,2.288-1.472A8.5,8.5,0,0,1,49,63.294a12.2,12.2,0,0,1,2.339.236,8.06,8.06,0,0,1,2.05.658v4.023q-.944-.447-1.8-.764a5.109,5.109,0,0,0-1.774-.315,4.079,4.079,0,0,0-2.354.671,2.486,2.486,0,0,0-.985,2.221,3.844,3.844,0,0,0,.814,2.629,3.585,3.585,0,0,0,2.789.919,4.553,4.553,0,0,0,1.88-.406,12.371,12.371,0,0,0,1.484-.776v3.969a7.989,7.989,0,0,1-1.932.843,9.071,9.071,0,0,1-2.459.288"
          transform="translate(-9.516 -58.274)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57064"
          data-name="Caminho 57064"
          d="M55.022,79.41a5.5,5.5,0,0,1-3.929-1.182,4.43,4.43,0,0,1-1.224-3.339V68.921H48.083V65.372H49.87V61.745h5.047v3.627h2.735v3.548H54.917v5.521a.919.919,0,0,0,.277.737,1.2,1.2,0,0,0,.8.236,2.371,2.371,0,0,0,.841-.184,2.823,2.823,0,0,0,.816-.472v4.023a4.486,4.486,0,0,1-1.327.5,6.823,6.823,0,0,1-1.3.13"
          transform="translate(-2.706 -59.93)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57065"
          data-name="Caminho 57065"
          d="M60.7,73.812a2.226,2.226,0,0,0,1.248-.354,2.3,2.3,0,0,0,.841-1.118,5.431,5.431,0,0,0,.3-1.971,6.041,6.041,0,0,0-.3-2.182,1.811,1.811,0,0,0-.841-1,3.13,3.13,0,0,0-2.511,0,1.683,1.683,0,0,0-.789,1,6.839,6.839,0,0,0-.263,2.182,4.521,4.521,0,0,0,.592,2.654,2.019,2.019,0,0,0,1.722.789m0,3.733a7.123,7.123,0,0,1-5.273-1.787,7.363,7.363,0,0,1-1.774-5.389q0-3.919,1.774-5.521a7.643,7.643,0,0,1,5.273-1.6,9.661,9.661,0,0,1,3.878.7A4.889,4.889,0,0,1,67,66.2a8.924,8.924,0,0,1,.828,4.167,7.194,7.194,0,0,1-1.84,5.389A7.277,7.277,0,0,1,60.7,77.544Z"
          transform="translate(3.26 -58.327)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57066"
          data-name="Caminho 57066"
          d="M61.645,77.11V65.279A5.1,5.1,0,0,1,63,64.426a13.521,13.521,0,0,1,2.17-.8,8.6,8.6,0,0,1,2.366-.344,11.048,11.048,0,0,1,2.6.238,3.549,3.549,0,0,1,1.366.6v3.391a7.7,7.7,0,0,0-1.064-.2q-.7-.093-1.486-.133t-1.472-.039a7.968,7.968,0,0,0-1.052.052V77.11Z"
          transform="translate(11.809 -58.288)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57067"
          data-name="Caminho 57067"
          d="M73.749,73.812a3.429,3.429,0,0,0,1.457-.275,1.817,1.817,0,0,0,.894-1.027,6.148,6.148,0,0,0,.3-2.194,5.276,5.276,0,0,0-.25-1.787,1.892,1.892,0,0,0-.789-1.012,2.778,2.778,0,0,0-1.457-.329,3.432,3.432,0,0,0-1.538.211.629.629,0,0,0-.329.551v5.47a3.552,3.552,0,0,0,.867.288,4.206,4.206,0,0,0,.843.106M75.4,77.544c-.209,0-.573-.012-1.091-.039a6.15,6.15,0,0,1-1.642-.327A3.19,3.19,0,0,1,71.2,76.151l.841-1.472v6.809H67.36V66.767a2.439,2.439,0,0,1,.5-1.524,3.9,3.9,0,0,1,1.393-1.106,7.909,7.909,0,0,1,2.13-.671,15.4,15.4,0,0,1,2.733-.224,9.478,9.478,0,0,1,3.747.671,4.9,4.9,0,0,1,2.418,2.248,9.183,9.183,0,0,1,.853,4.337,9.071,9.071,0,0,1-.7,3.666,5.8,5.8,0,0,1-1.971,2.486A5.215,5.215,0,0,1,75.4,77.544Z"
          transform="translate(17.926 -58.327)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57068"
          data-name="Caminho 57068"
          d="M80.954,77.5a12.24,12.24,0,0,1-2.5-.236,5.669,5.669,0,0,1-1.961-.776,3.518,3.518,0,0,1-1.273-1.472,5.291,5.291,0,0,1-.447-2.3,4.082,4.082,0,0,1,.472-1.958,3.478,3.478,0,0,1,1.511-1.42,5.938,5.938,0,0,1,2.722-.538,6.942,6.942,0,0,1,1.669.211,7.586,7.586,0,0,1,1.551.551,3.249,3.249,0,0,1,1.039.737l.079,1.971a4.458,4.458,0,0,0-1.064-.393,5.2,5.2,0,0,0-1.17-.132,3.381,3.381,0,0,0-1.6.277.986.986,0,0,0-.472.932,1.11,1.11,0,0,0,.2.619,1.58,1.58,0,0,0,.526.5,1.37,1.37,0,0,0,.723.2,5.434,5.434,0,0,0,1.578-.147.551.551,0,0,0,.393-.565V69.171A1.712,1.712,0,0,0,82.2,67.62a3.7,3.7,0,0,0-2.037-.474,7.275,7.275,0,0,0-4.234,1.472V64.465a7.814,7.814,0,0,1,2.079-.789,13.5,13.5,0,0,1,3.47-.4q.5,0,1.275.081a8.855,8.855,0,0,1,1.617.327,5.5,5.5,0,0,1,1.59.776,3.859,3.859,0,0,1,1.209,1.406,4.847,4.847,0,0,1,.46,2.248l-.025,6.229a2.322,2.322,0,0,1-.737,1.737,4.74,4.74,0,0,1-2.209,1.064,16.212,16.212,0,0,1-3.706.354"
          transform="translate(25.863 -58.288)"
          fill="#1a1818"
        />
        <path
          id="Caminho_57069"
          data-name="Caminho 57069"
          d="M86.132,82.057a8.274,8.274,0,0,1-1.526-.143,5.92,5.92,0,0,1-1.342-.408V77.695a6.441,6.441,0,0,0,.948.248,4.821,4.821,0,0,0,.787.066,2.283,2.283,0,0,0,1.012-.224,1.719,1.719,0,0,0,.764-.894,6.292,6.292,0,0,0,.408-1.985l.13,2.84L81.557,63.5h5.468L89.5,72.041h-.4l2.42-8.544H96.7L91.652,76.616a14.071,14.071,0,0,1-1.629,3.167,5.838,5.838,0,0,1-1.84,1.735,4.155,4.155,0,0,1-2.05.538"
          transform="translate(33.12 -58.057)"
          fill="#1a1818"
        />
      </g>
      <path
        id="Caminho_57101"
        data-name="Caminho 57101"
        d="M12.642,56.419a16,16,0,0,1,0,32V81.83a9.412,9.412,0,0,0,0-18.824Z"
        transform="translate(0.065 -56.419)"
        fill="#fc5166"
        fillRule="evenodd"
      />
      <rect
        id="Retângulo_5737"
        data-name="Retângulo 5737"
        width="6.588"
        height="6.588"
        transform="translate(6.117 12.705)"
        fill="#cb1a30"
      />
    </svg>
  );
};

export { Logo };
