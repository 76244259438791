import styled from 'styled-components';

export const ContentItem = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 5px;
`;

interface ItemButtonProps {
  active: boolean;
}
export const ItemButton = styled.button<ItemButtonProps>`
  cursor: pointer;
  border: 0px;
  padding: 15px 12px 15px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #1a1818;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  transition: 0.5s all;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid
    ${props => (props.active ? 'transparent' : '#cfd5e2')};
  background-color: ${props => (props.active ? '#fff' : 'transparent')};

  svg {
    transition: 0.5s all;
    ${props => props.active && 'transform: rotate(180deg);'};

    path {
      fill: #fc5166;
    }
  }

  @media (min-width: 768px) {
    padding: 24px 32px 21px 32px;
    font-size: 20px;
    line-height: 27px;
  }
`;

export const ContentDescription = styled.div<ItemButtonProps>`
  height: 0px;
  transition: 0.5s all;
  padding: 0px 12px 15px 24px;
  background-color: #fff;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;

  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  height: ${props => (props.active ? '100%' : '55px')};
  margin-top: ${props => (props.active ? 0 : -55)}px;

  @media (min-width: 768px) {
    height: ${props => (props.active ? '100%' : '73px')};
    margin-top: ${props => (props.active ? 0 : -73)}px;
  }
`;

export const Description = styled.p`
  color: #727882;
  font-size: 14px;
  line-height: 22px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
