import axios from 'axios';
import DoctorPayService from './doctorpay';

const api = axios.create({
  baseURL: 'http://doctorpay-env.eba-fr2hbqpd.us-east-1.elasticbeanstalk.com',
});

const viaCep = axios.create({
  baseURL: 'https://viacep.com.br',
});

export default {
  doctorpay: DoctorPayService(api, viaCep),
};
