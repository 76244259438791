import React from 'react';

const WhatsApp: React.FC = () => {
  return (
    <svg
      id="IconWhatsApp"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Retângulo_1563"
        data-name="Retângulo 1563"
        width="32"
        height="32"
        fill="none"
        opacity="0"
      />
      <path
        id="logo-whatsapp"
        d="M46.081,58.012h-.005A12.162,12.162,0,0,1,39.9,56.328l-.443-.262-4.594,1.2,1.226-4.458-.289-.458a12.15,12.15,0,1,1,10.284,5.663Zm6.658-9.048c-.365-.182-2.161-1.061-2.493-1.182s-.578-.182-.821.182-.943,1.179-1.156,1.424-.426.272-.79.09a9.982,9.982,0,0,1-2.935-1.8,10.952,10.952,0,0,1-2.03-2.516c-.213-.363-.023-.56.16-.741.164-.163.365-.424.547-.636a2.457,2.457,0,0,0,.365-.606.667.667,0,0,0-.03-.636c-.091-.181-.821-1.97-1.125-2.7-.3-.708-.6-.612-.821-.623s-.458-.013-.7-.013a1.344,1.344,0,0,0-.973.454,4.07,4.07,0,0,0-1.277,3.03,7.067,7.067,0,0,0,1.49,3.757,16.224,16.224,0,0,0,6.234,5.484,21.155,21.155,0,0,0,2.081.765,5.024,5.024,0,0,0,2.3.144,3.756,3.756,0,0,0,2.464-1.727,3.032,3.032,0,0,0,.213-1.727C53.348,49.237,53.1,49.145,52.739,48.964Z"
        transform="translate(-30.02 -30.052)"
        fill="#1a1818"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { WhatsApp };
