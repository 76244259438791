import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f2f6ff;
  padding: 72px 36px 60px 36px;

  @media (min-width: 768px) {
    padding: 130px 6% 80px 6%;
  }
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

export const Text = styled.h2`
  color: #1a1818;
  font-size: 24px;
  line-height: 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const CancelPrice = styled.p`
  color: #1a1818;
  font-size: 24px;
  line-height: 32px;
  text-decoration: line-through;
  margin-top: 9px;

  @media (min-width: 768px) {
    margin-top: 21px;
    font-size: 32px;
    line-height: 48px;
  }

  @media (min-width: 1366px) {
    margin-top: 19px;
  }
`;

export const Price = styled.h3`
  color: #00c170;
  font-size: 32px;
  line-height: 38px;
  margin-top: 13px;
  margin-bottom: 11px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 58px;
    margin-top: 0px;
    margin-bottom: 41px;
  }
`;

export const Scope = styled.div`
  display: flex;
  flex-direction: column;

  #small {
    max-width: 146px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  #large {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }
`;

export const CardMachine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 302px;
  height: 367.03px;
  background-size: cover;
  background-position: center;
  margin: 48px auto 60px auto;

  @media (min-width: 768px) {
    margin: 0px;
    width: 424px;
    height: 515.38px;
  }
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;

  @media (min-width: 768px) {
    margin-top: 120px;
  }

  @media (min-width: 1366px) {
    margin-top: 104px;
  }
`;

export const TextSpan = styled.span`
  color: #fc5166;
`;

export const ContentImage = styled.div`
  display: flex;
  width: 100%;
  max-height: 136px;
  margin: 23px 0px 10% 0px;

  @media (min-width: 768px) {
    max-height: 295px;
    margin: 45px 0px 10% 0px;
  }

  @media (min-width: 1366px) {
    max-height: 480px;
    margin: 45px 0px 60px 0px;
  }
`;

export const DesktopImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const Br = styled.br``;

export const SubText = styled.h4`
  color: #1a1818;
  font-size: 20px;
  line-height: 27px;
  margin-top: 10%;
  margin-bottom: 3px;
  max-width: 312px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
    margin-top: 10%;
  }
`;
